import React from 'react';

export const SmallCell = ({...props}) => {

    const {className} = props;

    return (
        <div className={`flex-.5 flex flex-row items-center justify-center p-1 ${className && className}`}>
            {props.children}
        </div>
    );
}

export const MediumCell = ({...props}) => {

    const {className} = props;

    return (
        <div className={`flex-2 flex flex-row items-center p-1 ${className && className}`}>
            {props.children}
        </div>
    );
}

export const LargeCell = ({...props}) => {
    const {className} = props;

    return (
        <div className={`flex-3 flex flex-row items-center p-1 ${className && className}`}>
            {props.children}
        </div>
    );
}