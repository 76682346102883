import React from 'react';

const TextInput = (
    {
        value,
        onChange,
        onBlur,
        required,
        placeholder = "",
        className = "",
        type = "text",
        label,
        name,
        ...props
    }) => {

    return (
        <>
            {label &&
            <div className="uppercase text-xs">{label}</div>
            }
            <input type={type}
                   placeholder={placeholder}
                   required={required}
                   value={value}
                   name={name}
                   onChange={onChange && onChange}
                   onBlur={onBlur && onBlur}
                   className={`${defaultStyle} ${required ? "bg-opacity-20" : ""} ${className}`}
            />
        </>
    )
}

const defaultStyle = `bg-white bg-opacity-5 mb-1 p-1 flex-1 rounded-md focus:outline-none focus:ring-1 focus:ring-inset focus:ring-eapp_yellow`;

export default TextInput;
