import API from './config';

const doLogin = async (name, password) => {
    const params = new URLSearchParams();
    params.append('email', name);
    params.append('password', password);

    let response = await API.post(`/login/admin`, params);
    console.log(response);
    if(response && response.data) {
        return response.data;
    } else {
        return response;
    }
}

export {
    doLogin,
}