import React, {useEffect, useState} from 'react';
import Title from "../components/UI/Title";
import useAuthCalls from "../utils/custom_hooks/auth_calls_hook";
import {doGetUserDeletionRequests} from "../logics/users";
import UserTable from "../components/DeletionRequests/UserTable";
import DownloadDeletedUsers from "../components/DeletionRequests/DownloadDeletedUsers";

const DeletionRequests = () => {

    const [users, setUsers] = useState([]);
    const {call} = useAuthCalls();

    const load = async () => {
        await call(
            () => doGetUserDeletionRequests(),
            (users_to_delete) => {
                console.log(users_to_delete);
                setUsers(users_to_delete);
            }
        )
    }

    const handleUserDelete = () => {
        load();
    }

    useEffect(() => {
        load();
    }, [])

    return (
        <div className="text-white h-full flex flex-col " style={{minHeight: "100px"}}>

            <div className="flex flex-row items-center justify-between mb-4">
                <Title>Richieste di cancellazione</Title>
                <DownloadDeletedUsers users={users} />
            </div>

            <UserTable users={users} onDelete={handleUserDelete}/>
        </div>
    );
};

export default DeletionRequests;