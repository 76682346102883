import React, {useEffect, useState} from 'react';
import {ProcessingProvider} from "../utils/context/processing-context";
import Job from "../components/Processings/Job";
import Title from "../components/UI/Title";
import useAuthCalls from "../utils/custom_hooks/auth_calls_hook";
import {doGetJobs} from "../logics/processings";

const Processings = () => {

    const {call} = useAuthCalls();

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const load = async () => {
            await call(
                () => doGetJobs(),
                (res) => setJobs(res),

            )
        }
        load();
    }, [])

    return (
        <div className="text-white">
            <Title>Gestione lavorazioni</Title>
            <div className="bg-eapp_black h-full">
                {jobs && jobs.length > 0
                && jobs.map((job, i) =>
                <ProcessingProvider key={i}>
                    <Job job={job} />
                </ProcessingProvider>
                )
                }
                {/*<ProcessingProvider>*/}
                {/*    <Job value={1} name={"Imbianchino"}/>*/}
                {/*</ProcessingProvider>*/}
                {/*<ProcessingProvider>*/}
                {/*    <Job value={2} name={"Elettricista"}/>*/}
                {/*</ProcessingProvider>*/}
                {/*<ProcessingProvider>*/}
                {/*    <Job value={3} name={"Piastrellista"}/>*/}
                {/*</ProcessingProvider>*/}
                {/*<ProcessingProvider>*/}
                {/*    <Job value={4} name={"Impresa edile"}/>*/}
                {/*</ProcessingProvider>*/}
                {/*<ProcessingProvider>*/}
                {/*    <Job value={5} name={"Idraulico"}/>*/}
                {/*</ProcessingProvider>*/}
                {/*<ProcessingProvider>*/}
                {/*    <Job value={6} name={"Falegname"}/>*/}
                {/*</ProcessingProvider>*/}
            </div>
        </div>
    );
}

export default Processings;