import React from 'react';
import userReducer, {defaultState} from "../reducers/user-reducer";
import actions from "../actions/user";

const UserContext = React.createContext();

function useUserContextValue() {
    const [state, dispatch] = React.useReducer(userReducer, defaultState);

    const {user_id, userSubscriptions, user} = state;

    const loadUserSubscriptions = React.useCallback((subscriptions) => dispatch(actions.loadUserSubscriptions(subscriptions)), []);
    const setUserId = React.useCallback((id) => dispatch(actions.setUserId(id)), []);
    const loadUser = React.useCallback((user) => dispatch(actions.loadUser(user)), []);

    return React.useMemo(() => {
        return {
            state,
            user_id,
            userSubscriptions,
            loadUserSubscriptions,
            setUserId,
            user, loadUser,
        }
    }, [
        state, user_id, userSubscriptions,
        loadUserSubscriptions, setUserId,
        user, loadUser,
    ])
}

function UserProvider(props) {
    const value = useUserContextValue();
    return <UserContext.Provider value={value} {...props} />
}

function useUser() {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }

    return context;
}

export {
    useUser,
    UserProvider,
}