import React from 'react';
import appReducer, {defaultState} from "../reducers/app-reducer";
import actions from '../actions/app';

const AppContext = React.createContext();

function useAppContextValue() {
    const [state, dispatch] = React.useReducer(appReducer, defaultState);

    const {authenticated, token, subscriptions, addons, toastVisible, toastContent} = state;

    const setAuthenticatedStatus = React.useCallback((status) => dispatch(actions.setAuthenticatedStatus(status)), []);
    const setToken = React.useCallback((token) => dispatch(actions.setToken(token)), []);
    const loadSubscriptions = React.useCallback((subscriptions) => dispatch(actions.loadSubscriptions(subscriptions)), []);
    const updateSubscription = React.useCallback((subscription) => dispatch(actions.updateSubscription(subscription)), []);
    const removeSubscription = React.useCallback((subscription) => dispatch(actions.removeSubscription(subscription)), []);
    const addSubscription = React.useCallback((subscription) => dispatch(actions.addSubscription(subscription)), []);
    const loadAddons = React.useCallback((addons) => dispatch(actions.loadAddons(addons)), []);
    const addAddon = React.useCallback((addon) => dispatch(actions.addAddon(addon)), []);
    const removeAddon = React.useCallback((id) => dispatch(actions.removeAddon(id)), []);
    const updateAddon = React.useCallback((addon) => dispatch(actions.updateAddon(addon)), []);
    const setToastContent = React.useCallback((content) => dispatch(actions.setToastContent(content)), []);
    const setToastVisible = React.useCallback((status) => dispatch(actions.setToastVisible(status)), []);

    return React.useMemo(() => {
        return {
            state,
            authenticated,
            token,
            subscriptions,
            addons,
            toastVisible,
            toastContent,
            setAuthenticatedStatus,
            setToken,
            loadSubscriptions,
            updateSubscription,
            removeSubscription,
            addSubscription,
            loadAddons,
            addAddon,
            removeAddon,
            updateAddon,
            setToastContent,
            setToastVisible,
        }
    }, [
        state, authenticated, token, subscriptions, addons, toastVisible, toastContent,
        setAuthenticatedStatus, setToken,
        loadSubscriptions, updateSubscription, removeSubscription, addSubscription,
        loadAddons, addAddon, removeAddon, updateAddon, setToastContent, setToastVisible,
    ])
}

function AppProvider(props) {
    const value = useAppContextValue();
    return <AppContext.Provider value={value} {...props} />
}

function useApp() {
    const context = React.useContext(AppContext);
    if (!context) {
        throw new Error("useApp must be used within an AppProvider");
    }
    return context;
}

export {
    AppProvider,
    useApp
}