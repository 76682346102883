import React, {useState} from 'react';
import {CgExtensionAdd, FaTrashAlt} from "react-icons/all";
import UserSubscriptionRow from "./UserSubscriptionRow";
import UserSubscriptionAddons from "./UserSubscriptionAddons";

const UserSubscription = ({...props}) => {

    const {userSubscription, onDelete} = props;

    const [open, setOpen] = useState(false);

    const handleClick = (e) => {
        e.stopPropagation();
        onDelete();
    }

    return (
        <div className=" bg-white bg-opacity-5 mb-1 odd:bg-opacity-10">
            <UserSubscriptionRow userSubscription={userSubscription}
                                 setOpen={() => setOpen(!open)}
                                 onDelete={handleClick}
                                 />
            {open &&
            <div className="p-2 pt-0">
                <UserSubscriptionAddons userSubscription={userSubscription}/>
            </div>
            }
        </div>
    )
}

export default UserSubscription;
