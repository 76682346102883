import React, {useState} from 'react';
import Images from '../images';
import {doLogin} from "../logics/login";
import storage from "../utils/storage";
import {useApp} from "../utils/context/app-context";

const Login = ({...props}) => {

    const {setAuthenticatedStatus} = useApp();

    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");

    const onClickAccessHandler = async () => {
        let response = await doLogin(user, password);
        console.log(response);
        if(response && response.token){
            await storage.storeData('@user_token', response.token);
            await setAuthenticatedStatus(true);
        }
        if(response && response.error) {
            alert(response.error);
        }
    }

    return(
        <div className={"bg-eapp_black w-screen h-screen flex flex-col items-center"}>

            <img src={Images.logo_header} className="my-8 pb-4"/>

            <div className={"bg-eapp_green p-4 rounded-lg"}>
                <div>Accedi al pannello di amministrazione</div>
                <div>
                    <input
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        className="bg-transparent w-full placeholder-white placeholder-opacity-50 p-2 border-b-2 border-white outline-none focus:border-0 focus:border-b-2 focus:border-eapp_yellow"
                        placeholder="utente"
                        type="text" />
                </div>
                <div>
                    <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="bg-transparent w-full placeholder-white placeholder-opacity-50 p-2 border-b-2 border-white outline-none focus:border-0 focus:border-b-2 focus:border-eapp_yellow"
                        placeholder="password"
                        type="password" />
                </div>

                <button
                    type="button"
                    onClick={onClickAccessHandler}
                    className="bg-eapp_yellow w-full mt-8 rounded-lg text-center font-bold uppercase py-1 cursor-pointer">Accedi</button>
            </div>

        </div>
    );
};

export default Login;
