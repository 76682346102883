import {USERS as types} from "../types";

function loadUsers(users) {
    return {
        type: types.USERS_LOAD,
        users: users,
    }
}

function addUser(user) {
    return {
        type: types.USERS_ADD_USER,
        user:user,
    }
}

export default {
    loadUsers,
    addUser,
}