import React, {useEffect} from 'react';
import Title from "../components/UI/Title";
import useAuthCalls from "../utils/custom_hooks/auth_calls_hook";
import {doGetAddons} from "../logics/addons";
import {useApp} from "../utils/context/app-context";
import AddonPanel from "../components/Addons/AddonPanel";
import AddonsCreationPanel from "../components/Addons/AddonsCreationPanel";

const Addons = ({...props}) => {

    const {addons, loadAddons} = useApp();
    const {call} = useAuthCalls();

    useEffect(() => {
        const loadData = async () => {
            call(
                () => doGetAddons(),
                (res) => loadAddons(res)
            );
        }

        loadData();
    }, [])

    return (
        <div className="text-white">
            <Title>Addons</Title>

            <AddonsCreationPanel />

            <div className="grid grid-cols-3 gap-2">
                {addons && addons.length > 0
                && addons.map((addon, i) =>
                    <AddonPanel addon={addon} key={i} />
                )
                }
            </div>
        </div>
    )
}

export default Addons;