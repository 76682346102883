import React from 'react';
import storage from "../utils/storage";
import {useApp} from "../utils/context/app-context";

const LogoutBtn = ({...props}) => {

    const {className} = props;

    const {setAuthenticatedStatus, setToken} = useApp();

    const logout = async () => {
        if(await storage.clear()) {
            setAuthenticatedStatus(false);
            setToken(false);
        }
    }

    return (
        <div onClick={logout} className={className}>
            {props.children}
        </div>
    );
}

export default LogoutBtn;