import React, {useEffect, useState} from 'react';
import UserCreationForm from "../components/Users/UserCreationForm";
import {doGetUsers} from "../logics/users";
import {useUsers} from "../utils/context/users-context";
import UsersTable from "../components/Users/UsersTable";
import useAuthCalls from "../utils/custom_hooks/auth_calls_hook";
import {useApp} from "../utils/context/app-context";
import Title from "../components/UI/Title";
import useFilter from "../utils/custom_hooks/filter_hook";
import {doGetAddons} from "../logics/addons";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import AlignedRow from "../components/UI/AlignedRow";

const Users = () => {

    const {users, loadUsers} = useUsers();
    const {setAuthenticatedStatus, loadAddons} = useApp();
    const {call} = useAuthCalls();
    const [loading, setLoading] = useState(true);

    const filterUsers = (user) => {

        if(filterText !== "") {
            let target = filterText.toLowerCase();

            let sameName = user.name ? user.name.toLowerCase().includes(target) : false;
            let sameEmail = user.email ? user.email.toLowerCase().includes(target) : false;
            let sameCompanyName = user.company_name ? user.company_name.toLowerCase().includes(target) : false;
            let samePhone = user.phone ? user.phone.toLowerCase().includes(target) : false;
            let sameAddress = user.address ? user.address.toLowerCase().includes(target) : false;

            return sameName || sameCompanyName || samePhone || sameAddress || sameEmail;
        }
        else return true;
    }

    const {filterText, setFilterText, filteredData} = useFilter(users, filterUsers, [])

    useEffect(() => {
        setLoading(true);
        const loadData = async () => {
            await call(
                () => doGetUsers(),
                async (response) => await loadUsers(response),
                "doGetUsers",
                async () => await setAuthenticatedStatus(false)
            );

            await call(
                () => doGetAddons(),
                (response) => loadAddons(response)
            )

            await setLoading(false);
        }

        loadData();
    }, []);

    return (
        <div className="text-white flex flex-col h-full" style={{minHeight: "100px"}}>
            <UserCreationForm/>
            <div>
                <input type="text"
                       value={filterText}
                       onChange={(e) => setFilterText(e.target.value)}
                       placeholder="Cerca utente"
                       className="bg-white rounded-lg p-1 mb-4 bg-opacity-5"/>
            </div>


            {loading
                ? <div className="flex flex-row items-center justify-center">
                    <AlignedRow className=" bg-eapp_green p-1 px-2 rounded-lg">
                        <div className="mr-2">Caricamento</div>
                        <LoadingSpinner />
                    </AlignedRow>

                </div>
                :<UsersTable users={filteredData}/>
            }

        </div>
    )
}

export default Users;