import React from 'react';
import field_types from "../../utils/types/fiend_types";

const UnitMeasureOption = ({...props}) => {
    const {value} = props;

    return(
        <option className="bg-white text-black hover:bg-eapp_green text-center" value={value}>{props.children}</option>
    );
}

const UnitMeasureSelect = ({...props}) => {

    const {value, onChange, className = ""} = props;

    const onChangeHandler = (val) => {
        let option = val.target.value;
        console.log(option);
        if(onChange)
            onChange(option);
    }

    return (
        <select className={`mr-4 outline-none bg-transparent text-white font-bold text-center ${className}`} onChange={onChangeHandler} value={value && value}>
            <UnitMeasureOption value={field_types.none}>Nessuno</UnitMeasureOption>
            <UnitMeasureOption value={field_types.MQ}>MQ</UnitMeasureOption>
            <UnitMeasureOption value={field_types.ML}>ML</UnitMeasureOption>
            <UnitMeasureOption value={field_types.MC}>MC</UnitMeasureOption>
            <UnitMeasureOption value={field_types.PZ}>pz</UnitMeasureOption>
            <UnitMeasureOption value={field_types.CAD}>cad</UnitMeasureOption>
            <UnitMeasureOption value={field_types.KG}>KG</UnitMeasureOption>
            <UnitMeasureOption value={field_types.L}>L</UnitMeasureOption>
            <UnitMeasureOption value={field_types.t}>t</UnitMeasureOption>
            <UnitMeasureOption value={field_types.H}>h</UnitMeasureOption>
            <UnitMeasureOption value={field_types.CM}>cm</UnitMeasureOption>
            <UnitMeasureOption value={field_types.MM}>mm</UnitMeasureOption>
            <UnitMeasureOption value={field_types.GG}>GG</UnitMeasureOption>
            <UnitMeasureOption value={field_types.SETT}>SETT</UnitMeasureOption>
            <UnitMeasureOption value={field_types.MESE}>MESE</UnitMeasureOption>
        </select>
    );
}

export default UnitMeasureSelect;
