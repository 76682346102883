import React from 'react';
import {UsersProvider, useUsers} from "../../utils/context/users-context";
import UserRow from "./UserRow";
import {LargeCell, MediumCell, SmallCell} from "../UI/TableCells";
import usePagination from "../../utils/custom_hooks/pagination_hook";
import {FaArrowLeft, FaArrowRight} from "react-icons/all";
import {UserProvider} from "../../utils/context/user-context";

const UsersTable = ({...props}) => {

    const {users} = props;

    const {prev, next, currentData, currentPage, maxPage, getInterval} = usePagination(users, 50)

    return (
        <div className=" rounded-lg bg-eapp_green bg-opacity-70 px-1 flex-1 flex flex-col" style={{minHeight: "100px"}}>
            <div className="flex flex-row font-bold mr-3 text-sm">
                <SmallCell>ID</SmallCell>
                <MediumCell>Nome</MediumCell>
                <MediumCell>Email</MediumCell>
                <MediumCell>Contatti</MediumCell>
                <SmallCell className="text-center">Preventivi</SmallCell>
                <SmallCell></SmallCell>
            </div>
            <div className="overflow-y-scroll bg-eapp_black flex-1" style={{minHeight: "30px"}}>
                {currentData() && currentData().length > 0 &&
                currentData().map(user =>
                    <UserProvider key={user.id}>
                        <UserRow user={user} />
                    </UserProvider>
                )
                }
            </div>
            <div className="p-1 flex flex-row justify-between">
                <div className="flex-1"></div>
                <div className="flex-1 flex flex-row items-center justify-center">
                    <FaArrowLeft onClick={prev}/>
                    <span className="mx-2">{currentPage} / {maxPage}</span>
                    <FaArrowRight onClick={next}/>
                </div>
                <div className="flex-1 flex flex-row justify-end">
                    {getInterval()} di {users.length}
                </div>
            </div>
        </div>
    );
}

export default UsersTable;