import React from "react";
import formatter from "../../utils/formatter";
import {FaTrashAlt} from "react-icons/all";
import {useUser} from "../../utils/context/user-context";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {doDeleteUserAddon} from "../../logics/addons";
import {doGetSubscriptionHistory, doGetUserSubscriptions} from "../../logics/subscriptions";
import useToast from "../../utils/custom_hooks/toast_hook";

const AddonRow = ({...props}) => {

    const {addon, userSubscription} = props;

    const {user_id, loadUserSubscriptions} = useUser();
    const {call} = useAuthCalls();
    const {displayConfirm} = useToast();

    console.log(userSubscription);

    const handleAddonDelete = async () => {

        await displayConfirm(
            `Cancellare l'addon "${addon?.name ?? addon?.addon?.name}"?`,
            async () => {
                await call(
                    () => doDeleteUserAddon(user_id, userSubscription.id, addon.id ?? addon.addon.id),
                    async (res) => {
                        if (res)
                            await call(
                                () => doGetSubscriptionHistory(user_id),
                                (res) => {
                                    if(res) {
                                        loadUserSubscriptions(res);
                                    }

                                },
                            )
                    },
                )
            }
        )
    }

    return (
        <div
            className={`flex flex-row items-center p-1 rounded-lg mb-1 ${addon.deleted_at ? "bg-red-400" : "bg-gray-200"} bg-opacity-30`}>
            <div className="flex-1 mr-1">{addon?.name ?? addon?.addon?.name}</div>
            {/*<div className="flex-.5 flex flex-row justify-end">*/}
            {/*    {!addon.deleted_at &&*/}
            {/*    <FaTrashAlt onClick={handleAddonDelete}*/}
            {/*                title="Elimina"*/}
            {/*                className="cursor-pointer hover:text-red-500"/>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    );
}

export default AddonRow;
