import React, {useState} from 'react';
import {FaToggleOff, FaToggleOn, FaTrashAlt, GiConfirmed, MdCancel} from "react-icons/all";
import AlignedRow from "../UI/AlignedRow";
import {doDeleteSubscription, doPutSubscription} from "../../logics/subscriptions";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {useApp} from "../../utils/context/app-context";
import EditableField from "../UI/EditableField";
import EditableType from "./EditableType";

const SubscriptionPanel = ({...props}) => {

    const {subscription} = props;

    const {updateSubscription, removeSubscription} = useApp();
    const {call} = useAuthCalls();

    const handleToggleActivable = async () => {
        await call(
            () => doPutSubscription(subscription.id, {activable: !subscription.activable}),
            (res) => {
                if(res && res.id) {
                    updateSubscription(res);
                }
            },
        );
    }

    const onClickDelete = async () => {
        await call(
            () => doDeleteSubscription(subscription.id),
            (res) => {
                if(res && res.message) {
                    removeSubscription(subscription.id);
                }
            }
        );
    }

    const handleValueChange = async (field) => {
        await call(
            () => doPutSubscription(subscription.id, field),
            (res) => {
                if(res.type)
                    updateSubscription(res)
            },
        );
    }

    return (
        <div className={`p-2 bg-white bg-opacity-10 rounded-lg border-2 ${subscription.activable ? "border-eapp_yellow" : "border-transparent" }`}>
            <AlignedRow className="justify-between">
                <div className="font-bold uppercase">{subscription.name}</div>
                <FaTrashAlt onClick={onClickDelete} className="hover:text-red-500 cursor-pointer"/>
            </AlignedRow>
            <EditableField label="Descrizione"
                           value={subscription.description ? subscription.description : "Nessuna descrizione"}
                           setValue={(val) => handleValueChange({description: val})}/>

            <EditableField value={subscription.price}
                           append={"€"}
                           label="prezzo"
                           setValue={(val) => handleValueChange({price: val})}/>
            <EditableField value={subscription.duration}
                           append={"gg"}
                           label="durata"
                           setValue={(val) => handleValueChange({duration: val})}/>

            <EditableType value={subscription.type}
                          label={"Tipo"}
                          onConfirm={(val) => handleValueChange({type: val})} />
            <AlignedRow>
                <div className="pb-1 mr-2">Acquistabile: </div>
                <div onClick={handleToggleActivable}
                    className="text-xl cursor-pointer">
                    {subscription.activable
                        ? <FaToggleOn className="text-green-400"/>
                        : <FaToggleOff className="opacity-50"/>
                    }
                </div>
            </AlignedRow>
        </div>
    )
}

export default SubscriptionPanel;
