import {USERS} from "../types";

export const defaultState = {
    users: []
}

function usersReducer(state, action) {
    switch (action.type) {
        case USERS.USERS_LOAD:
            return {
                ...state,
                users: action.users
            }
        case USERS.USERS_ADD_USER:
            return {
                ...state,
                users: [
                    action.user,
                    ...state.users,
                ]
            }
        default:
            console.log(action.type);
            throw new Error("Action type not implemented");
    }
}

export default usersReducer;
