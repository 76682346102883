
import React from "react";
import {useApp} from "../context/app-context";

function useToast() {

    const {setToastContent, setToastVisible} = useApp();

    async function displayError(content) {
        await setToastContent(
            <div className={"rounded-md p-2 bg-red-600 shadow-lg mt-1"}>
                <div className={"text-white font-bold text-center"}>
                    {content}
                </div>
            </div>
        );
        await setToastVisible(true);
        await setTimeout(() => {
            setToastVisible(false);
        }, 3000);
    }

    async function displayWarning(content) {
        await setToastContent(
            <div className={"rounded-md p-2 bg-yellow-500 shadow-lg mt-1"}>
                <div className={"text-white font-bold text-center"}>
                    {content}
                </div>
            </div>
        );
        await setToastVisible(true);
        await setTimeout(() => {
            setToastVisible(false);
        }, 3000);
    }

    async function displayConfirm(message, onSuccess, onCancel) {
        await setToastVisible(true);
        await setToastContent(
            <div className="rounded-md p-2 bg-eapp_black border-2 border-eapp_yellow shadow-lg mt-1">
                <div className={"text-white mb-4"}>
                    {message}
                </div>
                <div className="flex flex-row items-center justify-around text-white">
                    <div className="rounded p-1 bg-eapp_green bg-opacity-80 hover:bg-opacity-100 cursor-pointer" onClick={() => {
                        onSuccess && onSuccess();
                        setToastVisible(false);
                    }}>
                        Conferma
                    </div>
                    <div className="rounded p-1 bg-red-600 bg-opacity-80 hover:bg-opacity-100 cursor-pointer" onClick={() => {
                        onCancel && onCancel();
                        setToastVisible(false);
                    }}>
                        Annulla
                    </div>
                </div>
            </div>
        )
    }

    async function displayToast(message) {
        await setToastContent(
            <div className={"rounded-md p-2 bg-eapp_green shadow-lg mt-1"}>
                <div className={"text-white font-bold text-center"}>
                    {message}
                </div>
            </div>
        );
        await setToastVisible(true);
        await setTimeout(() => {
            setToastVisible(false);
        }, 3000);
    }

    return {displayError, displayWarning, displayConfirm, displayToast}
}

export default useToast;
