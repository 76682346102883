import React, {useState} from 'react';
import YellowBtn from "../UI/YellowBtn";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {doPostSubscription} from "../../logics/subscriptions";
import {useApp} from "../../utils/context/app-context";

const SubscriptionCreationPanel = ({...props}) => {

    const {onSubmit} = props;

    const {call} = useAuthCalls();
    const {addSubscription} = useApp();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [duration, setDuration] = useState(360);
    const [type, setType] = useState("initial");

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (name !== "") {
            const fields = {
                name: name,
                description: description,
                price: price,
                duration: duration > 0 ? duration : 1,
                type: type
            }

            await call(
                () => doPostSubscription(fields),
                (res) => {
                    if (res) {
                        addSubscription(res);
                    }
                }
            );

            onSubmit && onSubmit();
        }
    }

    const handleRadioChange = (e) => {
        console.log(e.target.value);
    }

    return (
        <form className="flex flex-col justify-start items-start" onSubmit={handleSubmit}>
            <div>Nome</div>
            <input placeholder="Nome"
                   className="mb-2 bg-opacity-10 bg-white rounded p-1"
                   value={name}
                   onChange={(e) => setName(e.target.value)}
            />
            <div>Descrizione</div>
            <input placeholder="Descrizione"
                   className="mb-2 bg-opacity-10 bg-white rounded p-1"
                   value={description}
                   onChange={(e) => setDescription(e.target.value)}
            />
            <div>Prezzo</div>
            <input placeholder="Prezzo"
                   className="mb-2 bg-opacity-10 bg-white rounded p-1"
                   type={"number"}
                   value={price}
                   onChange={(e) => setPrice(e.target.value)}
            />
            <div>Durata in giorni</div>
            <input placeholder="Durata in gg"
                   className="mb-2 bg-opacity-10 bg-white rounded p-1"
                   type={"number"}
                   value={duration}
                   onChange={(e) => setDuration(e.target.value)}
            />

            <div onChange={handleRadioChange}>
                <div className="flex flex-row items-center">
                    <label className="mr-2" htmlFor="initial">Abbonamento</label>
                    <input type="radio" id="initial" value="initial" name="type" label="Abbonamento" defaultChecked/>
                </div>
                <div className="flex flex-row items-center">
                    <label className="mr-2" htmlFor="job">Listino</label>
                    <input type="radio" id="job" value="job" name="type" label="Listino"/>
                </div>
            </div>

            <YellowBtn type="submit">Crea</YellowBtn>

        </form>
    );
}

export default SubscriptionCreationPanel;