import React, {useState} from 'react';
import {useApp} from "../../utils/context/app-context";
import {useUser} from "../../utils/context/user-context";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import useToast from "../../utils/custom_hooks/toast_hook";
import {doPostUserAddon} from "../../logics/addons";
import {doGetSubscriptionHistory, doGetUserSubscriptions} from "../../logics/subscriptions";
import AddonRow from "./AddonRow";
import YellowBtn from "../UI/YellowBtn";

const UserSubscriptionAddons = ({...props}) => {
    const {userSubscription} = props;

    const {addons} = useApp();
    const {user_id, loadUserSubscriptions} = useUser();
    const {call} = useAuthCalls();
    const {displayWarning} = useToast();

    const [addonId, setAddonId] = useState("");

    const onConfirm = async () => {
        if(addonId !== "" && user_id !== null && userSubscription && userSubscription.id) {
            await call(
                () => doPostUserAddon(user_id, userSubscription.id, addonId),
            async (res) => {
                    if(res.addon) {
                        await call(
                            () => doGetSubscriptionHistory(user_id),
                            (res) => loadUserSubscriptions(res),
                        )
                    }
            },
                'doPostUserAddon',
                () => {displayWarning("Addon già presente")}
            )
        } else {
            displayWarning("Seleziona un'opzione");
        }
    }

    return (
        <div className="p-0.5 mt-1 bg-white bg-opacity-10 text-xs">

            <div className="">
                <div className="uppercase mb-1">Pacchetti aggiunti</div>

                <div className="flex flex-row items-center gap-2">
                    {userSubscription.users_addons && userSubscription.users_addons.length > 0
                        ? userSubscription.users_addons.map((addon, i) =>
                            <AddonRow key={i} addon={addon} userSubscription={userSubscription}/>
                        )
                        : <div>Nessun addon inserito</div>
                    }
                </div>
            </div>

            <div className="mt-4">
                <div>Seleziona pacchetti da aggiungere</div>
                <select className="bg-white bg-opacity-5 p-1 rounded-full outline-none"
                        value={addons && addons.length > 0 ? addonId : ""}
                        onChange={(e) => setAddonId(e.target.value)}>
                    <option disabled={true} value={""} className="bg-black">Seleziona un'opzione</option>
                    {addons && addons.length > 0 &&
                    addons.map((addon, i) =>
                        <option className="bg-black" key={i} value={addon.id}>{addon.name}</option>
                    )
                    }
                </select>
                <YellowBtn className={"ml-4"} onClick={onConfirm}>Aggiungi</YellowBtn>
            </div>
        </div>
    )
}

export default UserSubscriptionAddons;
