import React from 'react';

const AlignedRow = ({className, ...props}) => {

    return (
        <div className={`flex flex-row items-center ${className}`}>
            {props.children}
        </div>
    )
}

export default AlignedRow;
