import API from "./config";

async function doGetUsers() {
    return await API.get('/users');
}

async function doPostUser(fields) {
    let params = new URLSearchParams({...fields})
    return await API.post('/admin/users/', params);
}

async function doPostChangePassword(id, password) {
    let params = new URLSearchParams({password:password});
    return await API.post(`/admin/users/${id}/change_password`, params);
}

async function doGetUser(id) {
    return API.get(`/users/${id}`);
}

async function doGetUserDeletionRequests() {
    return API.get('/users/?deletion_request=1');
}

async function doDeleteUserForever(user_id) {
    return API.DELETE(`/admin/users/${user_id}`);
}

export {
    doGetUsers,
    doPostUser,
    doPostChangePassword,
    doGetUser,
    doGetUserDeletionRequests,
    doDeleteUserForever,
}