import React, {useEffect, useState} from 'react';
import {useApp} from "../../utils/context/app-context";
import {useUser} from "../../utils/context/user-context";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import useToast from "../../utils/custom_hooks/toast_hook";
import {
    doDeleteUserSubscription, doGetSubscriptionHistory,
    doGetSubscriptions,
} from "../../logics/subscriptions";
import UserSubscription from "./UserSubscription";
import LoadingSpinner from "../UI/LoadingSpinner";
import UserSubscriptionsHeader from "./UserSubscriptionsHeader";
import UserSubscriptionAddSubscriptionPanel from "./UserSubscriptionAddSubscriptionPanel";
import UserDetails from "./UserDetails";

const UserSubscriptionPanel = ({...props}) => {

    const {user} = props;

    const {userSubscriptions, loadUserSubscriptions} = useUser();
    const {call} = useAuthCalls();
    const {subscriptions, loadSubscriptions} = useApp();
    const {displayConfirm} = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const onClickDelete = async (subscription) => {

        await displayConfirm(
            `Cancellare l'abbonamento "${subscription.subscription.name}" di "${user.name ?? user.company_name}"`,
            async () => {
                await call(
                    () => doDeleteUserSubscription(user.id, subscription.id),
                    async (res) => {
                        if (res && res.deadline) {
                            setIsLoading(true);
                            await call(
                                () => doGetSubscriptionHistory(user.id),
                                (res) => {
                                    if (res) {
                                        loadUserSubscriptions(res);
                                    }
                                    setIsLoading(false);
                                },
                            )
                        }
                    },
                )
            }
        );
    }

    const removeUserSubscription = (id) => {
        let _userSubscriptions = userSubscriptions.filter((sub) => sub.id !== id);
        loadUserSubscriptions([..._userSubscriptions]);
    }

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);
            await call(
                () => doGetSubscriptionHistory(user.id),
                (res) => {
                    if (res) {
                        loadUserSubscriptions(res);
                    }
                    setIsLoading(false);
                },
            )

            if (!subscriptions || subscriptions.length < 1) {
                await call(
                    () => doGetSubscriptions(),
                    (res) => loadSubscriptions(res),
                )
            }

            await call(
                () => doGetSubscriptionHistory(user.id),
                (res) => {
                    loadUserSubscriptions(res);
                }
            )
        }

        load();
    }, []);

    return (
        <div className="bg-eapp_black p-2 rounded-lg">
            <UserDetails />
            <div>
                <div className="border-b-2 border-gray-400 mb-2 uppercase text-xs font-medium text-gray-400 border-b-2 border-gray-400">Abbonamenti</div>
                <UserSubscriptionsHeader/>
                {isLoading
                    ? <LoadingSpinner/>
                    : userSubscriptions && userSubscriptions.length > 0
                        ? userSubscriptions.map((userSub, i) =>
                            <UserSubscription userSubscription={userSub}
                                              onDelete={() => onClickDelete(userSub)}
                                              key={i}/>
                        )
                        : <div className="text-gray-400 italic">Nessun abbonamento presente</div>
                }
            </div>
            <div className="text-xs mt-4">
                <UserSubscriptionAddSubscriptionPanel userSubscriptions={userSubscriptions}/>
            </div>
        </div>
    )
}

export default UserSubscriptionPanel;
