import React from 'react';
import {NavLink} from "react-router-dom";
import {BiLogOut, BiNews, CgExtensionAdd, FaBan, FaList, FaUsers, IoCalendar} from "react-icons/all";
import LogoutBtn from "./LogoutBtn";

const activeClassName = "bg-eapp_black hover:bg-opacity-70";
const btnClassName = "flex flex-row items-center text-white font-bold w-full text-center px-4 py-2 rounded-lg hover:bg-opacity-30 hover:bg-black cursor-pointer"
const iconClass = "mr-4 text-lg";

const NavBtn = ({...props}) => {
    const {to} = props;

    return (
        <NavLink to={to} activeClassName={activeClassName} className={btnClassName}>
            {props.children}
        </NavLink>
    );
}

const SideNavBar = ({...props}) => {
    return (
        <div className="flex flex-col items-center">
            <NavBtn to={"/processings"}>
                <FaList className={iconClass} />
                Gestione lavorazioni
            </NavBtn>
            <NavBtn to={"/users"}>
                <FaUsers className={iconClass}/>
                Utenti
            </NavBtn>
            {/*<NavBtn to={"/subscriptions"}>*/}
            {/*    <IoCalendar className={iconClass} />*/}
            {/*    Abbonamenti*/}
            {/*</NavBtn>*/}
            {/*<NavBtn to={"/addons"}>*/}
            {/*    <CgExtensionAdd className={iconClass} />*/}
            {/*    Addons*/}
            {/*</NavBtn>*/}
            <NavBtn to={"/news"}>
                <BiNews className={iconClass} />
                News
            </NavBtn>
            <NavBtn to="/deletion_requests">
                <FaBan className={iconClass} />
                Richieste cancellazione
            </NavBtn>
            <LogoutBtn className={btnClassName}>
                <BiLogOut className={iconClass} />
                Logout
            </LogoutBtn>

        </div>
    );
}

export default SideNavBar;