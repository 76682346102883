import React, {useState} from "react";
import {GiConfirmed, MdCancel} from "react-icons/all";
import formatter from "../../utils/formatter";

const EditableType = ({...props}) => {

    const {label, onConfirm, value} = props;
    const [isEditing, setIsEditing] = useState(false);
    const [selectedType, setSelectedType] = useState(value);

    const handleConfirm = () => {
        setIsEditing(false);
        onConfirm(selectedType);
    }

    return (
        <div>
            <div className="uppercase text-xs text-gray-400">
                {label ? label : ""}
            </div>
            {isEditing
                ? <div className="flex flex-row items-center">
                    <div className="flex-1" onChange={(e) => setSelectedType(e.target.value)}>
                        <div className="flex flex-row items-center">
                            <label className="mr-2" htmlFor="initial" >Abbonamento</label>
                            <input type="radio" id="initial" value="initial" name="type" label="Abbonamento" defaultChecked={value==="initial"}/>
                        </div>
                        <div className="flex flex-row items-center">
                            <label className="mr-2" htmlFor="job">Listino</label>
                            <input type="radio" id="job" value="job" name="type" label="Listino" defaultChecked={value==="job"}/>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <GiConfirmed title="Conferma"
                                     onClick={handleConfirm}/>
                        <MdCancel title="Annulla"
                                  onClick={() => setIsEditing(false)}/>
                    </div>

                </div>
                : <div onDoubleClick={() => setIsEditing(true)}>
                    {formatter.formatSubType(value)}
                </div>
            }


        </div>
    )
}

export default EditableType;