import React from 'react';
import processingReducer, {defaultState} from "../reducers/processing-reducer";
import actions from '../actions/processing';

const ProcessingContext = React.createContext();

function useProcessingContextValue() {
    const [state, dispatch] = React.useReducer(processingReducer, defaultState);

    const {processings} = state;

    const loadProcessings = React.useCallback((processings) => dispatch(actions.loadProcessings(processings)), []);
    const addProcessing = React.useCallback((processing) => dispatch(actions.addProcessing(processing)), []);
    const renameProcessing = React.useCallback((id, name) => dispatch(actions.renameProcessing(id, name)), []);
    const deleteProcessing = React.useCallback((id) => dispatch(actions.deleteProcessing(id)), []);

    return React.useMemo(() => {
        return {
            state,
            processings,
            loadProcessings,
            addProcessing,
            renameProcessing,
            deleteProcessing,
        }
    }, [
        state, processings,
        loadProcessings, addProcessing, renameProcessing, deleteProcessing,
    ]);
}

function ProcessingProvider(props) {
    const value = useProcessingContextValue();
    return <ProcessingContext.Provider value={value} {...props} />;
}

function useProcessing() {
    const context = React.useContext(ProcessingContext);
    if(!context) {
        throw new Error("useProcessing must be used within a ProcessingProvider");
    }

    return context;
}

export {
    ProcessingProvider,
    useProcessing,
}