import React, {useEffect, useState} from 'react';
import {doGetNews} from "../logics/news";
import useAuthCalls from "../utils/custom_hooks/auth_calls_hook";
import Title from "../components/UI/Title";
import NewsRow from "../components/News/NewsRow";
import YellowBtn from "../components/UI/YellowBtn";
import NewsForm from "../components/News/NewsForm";

const News = () => {

    const [news, setNews] = useState([]);
    const [formVisible, setFormVisible] = useState(false);

    const {call} = useAuthCalls();

    const displayForm = () => {
        setFormVisible(!formVisible);
    }

    const load = async () => {
        await call(
            () => doGetNews(),
            (res) => setNews(res)
        )
    }

    const onReload = async () => {
        await load();
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <div className="text-white">
            <Title>News</Title>

            {!formVisible && <YellowBtn onClick={displayForm} className="mb-4">Aggiungi news</YellowBtn>}

            {formVisible &&
            <NewsForm onClose={() => setFormVisible(false)} onReload={onReload}/>}

            <div>
                <div>Lista news inserite</div>
                <div className="border border-opacity-20 p-1">
                    {news && news.length > 0 ?
                        news.map((single_news, i) => <NewsRow news={single_news} key={`${single_news.id}_${i}`} onReload={onReload}/>)
                        :
                        <div>Nessuna news inserita</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default News;