import API from "./config";

async function doGetAddons() {
    return await API.get(`/admin/addons`);
}

async function doPostAddon(name) {
    const params = new URLSearchParams();
    params.append("name", name);
    return await API.post(`/admin/addons/`, params);
}

async function doPutAddon(id, fields) {
    return await API.put(`/admin/addons/${id}`, fields);
}

async function doDeleteAddon(id) {
    return await API.get(`/admin/addons/${id}`);
}

async function doPostUserAddon(user_id, user_sub_id, addon_id) {
    return await API.post(`/admin/users/${user_id}/subscriptions/${user_sub_id}/addons/${addon_id}`);
}

async function doDeleteUserAddon(user_id, user_sub_id, addon_id) {
    return await API.DELETE(`/admin/users/${user_id}/subscriptions/${user_sub_id}/addons/${addon_id}`);
}

export {
    doGetAddons,
    doPostAddon,
    doPutAddon,
    doPostUserAddon,
    doDeleteUserAddon,
}