import {PROCESSINGS as types} from "../types";


function loadProcessings (processings){
    return {
        type: types.PROCESSINGS_LOAD,
        processings: processings,
    }
}

function addProcessing(processing) {
    return {
        type: types.PROCESSINGS_ADD_PROCESSINGS,
        processing: processing,
    }
}

function renameProcessing(id, name) {
    return {
        type: types.PROCESSINGS_RENAME_PROCESSING,
        id: id,
        name: name,
    }
}

function deleteProcessing(id) {
    return {
        type: types.PROCESSINGS_DELETE_PROCESSING,
        id: id,
    }
}

export default {
    loadProcessings,
    addProcessing,
    renameProcessing,
    deleteProcessing,
}