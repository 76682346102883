function formatDate(date) {
    if(date) {
        let _date = "";
        if (date.includes('T'))
            _date = date.split('T');
        else
            _date = date.split(' ');

        _date = _date[0].split("-");

        return `${_date[2]}/${_date[1]}/${_date[0]}`
    } else return "";
}

function dateToInputDate(date) {
    let _date = "";
    if (date.includes('T'))
        _date = date.split('T');
    else
        _date = date.split(' ');

    return _date[0];
}

function dateToDBDate(date) {
    return date + "T00:00:00+01:00";
}

const formatSubType = (value) => {
    switch (value) {
        case "initial":
            return "Abbonamento";
        case "job":
            return "Listino";
        case "trial":
            return "Prova";
        default:
            return "ERRORE";
    }
}

const formatMissingDays = (value) => {
    if (value) {
        return parseFloat(value).toFixed(0);
    } else {
        return "---";
    }
}

const formatter = {
    formatDate,
    formatSubType,
    formatMissingDays,
    dateToInputDate,
    dateToDBDate,
}

export default formatter;
