import API from "./config";

async function doGetSubscriptions() {
    return await API.get('/admin/subscriptions');
}

async function doPostSubscription(fields) {
    const params = new URLSearchParams();
    params.append("name", fields.name);
    params.append("description", fields.description);
    params.append("price", fields.price);
    params.append("duration", fields.duration);
    params.append("type", fields.type);
    return await API.post('/admin/subscriptions/', params);
}

async function doPutSubscription(id, fields) {
    return await API.put(`/admin/subscriptions/${id}`, fields);
}

async function doDeleteSubscription(id) {
    return await API.DELETE(`/admin/subscriptions/${id}`);
}

async function doGetUserSubscriptions(id) {
    return await API.get(`/users/${id}/subscriptions`);
}

async function doPostUserSubscription(user_id, subscription_id) {
    return await API.post(`/admin/users/${user_id}/subscriptions/${subscription_id}`);
}

async function doPutUserSubscription(user_id, subscription_id, fields) {
    return await API.put(`/admin/users/${user_id}/subscriptions/${subscription_id}`, fields);
}

async function doDeleteUserSubscription(user_id, subscription_id) {
    return await API.DELETE(`/admin/users/${user_id}/subscriptions/${subscription_id}`);
}

async function doGetSubscriptionHistory(id) {
    return API.get(`/users/${id}/subscriptions/history`);
}

export {
    doGetSubscriptions,
    doPostSubscription,
    doPutSubscription,
    doDeleteSubscription,
    doGetUserSubscriptions,
    doPostUserSubscription,
    doPutUserSubscription,
    doDeleteUserSubscription,
    doGetSubscriptionHistory,
}