import {APP as types} from "../types";

function setAuthenticatedStatus(status) {
    return {
        type: types.APP_SET_AUTHENTICATED_STATUS,
        status: status,
    }
}

function setToken(token) {
    return {
        type: types.APP_SET_TOKEN,
        token: token,
    }
}

function loadSubscriptions(subscriptions) {
    return {
        type: types.APP_LOAD_SUBSCRIPTIONS,
        subscriptions: subscriptions,
    }
}
function updateSubscription(subscription) {
    return {
        type: types.APP_UPDATE_SUBSCRIPTION,
        subscription: subscription,
    }
}
function removeSubscription(id) {
    return {
        type: types.APP_REMOVE_SUBSCRIPTION,
        id: id,
    }
}
function addSubscription(subscription) {
    return {
        type: types.APP_ADD_SUBSCRIPTION,
        subscription: subscription,
    }
}

function loadAddons(addons) {
    return {
        type: types.APP_LOAD_ADDONS,
        addons: addons,
    }
}
function addAddon(addon) {
    return {
        type: types.APP_ADD_ADDON,
        addon: addon,
    }
}
function removeAddon(id) {
    return {
        type: types.APP_REMOVE_ADDON,
        id: id,
    }
}
function updateAddon(addon) {
    return {
        type: types.APP_UPDATE_ADDON,
        addon: addon,
    }
}

function setToastContent(content) {
    return {
        type: types.APP_SET_TOAST_CONTENT,
        content: content,
    }
}

function setToastVisible(status) {
    return {
        type: types.APP_SET_TOAST_VISIBLE,
        status: status,
    }
}

export default {
    setAuthenticatedStatus,
    setToken,
    loadSubscriptions,
    updateSubscription,
    removeSubscription,
    addSubscription,
    loadAddons,
    addAddon,
    removeAddon,
    updateAddon,
    setToastContent,
    setToastVisible,
}