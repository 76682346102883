const ML = "linear_meter";
const MQ = "square_meter";
const MC = "cube_meter";
const PZ = "piece";
const KG = "kilogram";
const L = "liter";
const t = "ton";
const H = "hour";
const GG = "day";
const SETT = "week";
const MESE = "month";
const CAD = "cadauno";
const CM = "centimeters";
const MM = "millimeters";
const custom = "custom";
const none = "";

const field_types = {
    ML,
    MQ,
    MC,
    PZ,
    KG,
    L,
    t,
    H,
    GG,
    SETT,
    MESE,
    CAD,
    CM,
    MM,
    custom
}

export default field_types;
