import API from "./config";

const doGetProcessingsOfJob = async (job_id) => {
    return await API.get(`/addons/${job_id}/processings/`);
}

const doGetJobs = async () => {
    return await API.get("/admin/jobs");
}

const doPostProcessing = async (job_id, name) => {
    const params = new URLSearchParams();
    params.append("name", name);
    return await API.post(`/admin/jobs/${job_id}/processings/`, params);
}

const doGetStepsOfProcessing = async (processing_id) => {
    return await API.get(`/processings/${processing_id}/steps`);
}

const doPostStep = async (processing_id, name) => {
    const params = new URLSearchParams();
    params.append("processings", processing_id);
    params.append("name", name);

    return await API.post(`/steps/`, params);
}

const doGetFieldsOfStep = async (step_id) => {
    return await API.get(`/steps/${step_id}/fields/`);
}

const doPostField = async (step_id, type, label, value) => {
    const params = new URLSearchParams();
    params.append("step", step_id);
    params.append("type", type);
    params.append("label", label);
    params.append("value", value);

    return await API.post(`/fields/`, params);
}

const doPutProcessing = async (id, fields) => {
    return await API.put(`/processings/${id}`, fields);
}

const doDeleteField = async (id) => {
    return await API.DELETE(`/fields/${id}`);
}

const doPutCategory = async (id, fields) => {
    return await API.put(`/steps/${id}`, fields);
}

const doPutField = async (id, fields) => {
    return await API.put(`/fields/${id}`, fields);
}

const doDeleteStep = async (id) => {
    return await API.DELETE(`/steps/${id}`);
}

const doDeleteProcessing = async (id) => {
    return await API.DELETE(`/processings/${id}`);
}

const doPutReorderField = async (id, end_weight) => {
    let fields = {
        end_weight: end_weight,
    }
    return await API.put(`/fields/${id}/reorder`, fields);
}

const doPutReorderProcessings = async (id, end_weight) => {
    let fields = {
        end_weight: end_weight,
    }
    return await API.put(`/processings/${id}/reorder`, fields);
}

const doPutReorderSteps = async (id, end_weight) => {
    let fields = {
        end_weight: end_weight,
    }
    return await API.put(`/steps/${id}/reorder`, fields);
}

export {
    doGetProcessingsOfJob,
    doGetJobs,
    doPostProcessing,
    doGetStepsOfProcessing,
    doPostStep,
    doGetFieldsOfStep,
    doPostField,
    doPutProcessing,
    doDeleteField,
    doPutCategory,
    doPutField,
    doDeleteStep,
    doDeleteProcessing,
    doPutReorderField,
    doPutReorderProcessings,
    doPutReorderSteps,
}
