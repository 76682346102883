import React, {useEffect} from 'react';
import TextInput from "../UI/TextInput";
import YellowBtn from "../UI/YellowBtn";

const NewsFormComponent = ({onSubmit, onUndo, className = "", currentNews = null}) => {

    const formRef = React.useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit && onSubmit(e);
    }

    useEffect(() => {
        if (currentNews) {
            formRef.current.title.value = currentNews.title ? currentNews.title : "";
            formRef.current.short_description.value = currentNews.short_description ? currentNews.short_description : "";
            formRef.current.description.value = currentNews.description ? currentNews.description : "";
            formRef.current.deadline.value = currentNews.deadline ? currentNews.deadline : "";
            formRef.current.weight.value = currentNews.weight !== null && currentNews.weight !== "" ? currentNews.weight : "";
        }
    }, [currentNews]);

    return (
        <form className={`flex flex-col ${className}`} onSubmit={handleSubmit} ref={formRef}>
            <TextInput placeholder="Titolo"
                       name="title"
                       required={true}
                       label="Titolo"/>
            <TextInput placeholder="Max 250 caratteri"
                       name="short_description"
                       label="Breve descrizione"/>
            <div className="uppercase text-xs">Descrizione</div>
            <textarea name="description"
                      placeholder="Testo news"
                      style={{minHeight: "200px", maxHeight: "500px"}}
                      className="bg-white bg-opacity-5 mb-1 p-1 rounded-md focus:outline-none focus:ring-1 focus:ring-inset focus:ring-eapp_yellow resize-y"/>
            <div className="flex flex-row gap-2">
                <div className="flex-1">
                    <TextInput type="date"
                               name="deadline"
                               label="Scadenza"
                               className="w-full"/>
                </div>
                <div className="flex-1">
                    <TextInput type="number"
                               name="weight"
                               label="Priorità"
                               placeholder="Priorità"
                               className="w-full"/>
                </div>
            </div>

            <div className="flex flex-row justify-around mt-2">
                {onUndo &&
                <YellowBtn type="button" onClick={() => onUndo && onUndo()}>Annulla</YellowBtn>
                }
                <YellowBtn type="submit">Conferma</YellowBtn>
            </div>
        </form>
    );
};

export default NewsFormComponent;
