import React from 'react';
import {useApp} from "../context/app-context";

function useAuthCalls() {

    const {setAuthenticatedStatus} = useApp();

    async function call(func, onSuccess = null, label = "", onFailure = null) {
        console.groupCollapsed(`CALLING ${label}`);
        let response = await func();
        console.log(response);
        if(response) {
            if(!response.status || response.status === 403) {
                console.log("NOT AUTH")
                await setAuthenticatedStatus(false);
                console.groupEnd();
                if(onFailure) {
                    console.log("CALLING ON FAILURE");
                    onFailure(response);
                }
            } else {
                if(response.data) {
                    console.log(response.data);
                    if(response.data.length && response.data.length > 0) console.table(response.data);
                    console.groupEnd();
                    if(onSuccess)
                        onSuccess(response.data);
                    else
                        return response.data;
                }else {
                    if(onFailure) {
                        console.log("CALLING ON FAILURE");
                        onFailure(response?.response?.data ?? response?.response ?? response);
                        console.groupEnd();
                    }
                }
            }
        }

        console.groupEnd();
    }

    return {call};
}

export default useAuthCalls;