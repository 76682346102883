import axios from "axios";
import storage from "../utils/storage";

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_KEY = process.env.REACT_APP_API_KEY;

const get = async (url) => {
    try {
        const instance = await getInstance();
        return await instance.get(url);
    } catch (error) {
        return handleError(error);
    }
}

const post = async (url, data) => {
    try {
        const instance = await getInstance();
        return await instance.post(url, data);
    } catch (error) {
        return handleError(error);
    }
}

const put = async (url, data) => {
    try {
        const instance = await getInstance();
        return await instance.put(url, data, {headers: {"Content-Type": "application/json"}});
    } catch (error) {
        return handleError(error);
    }
}

const handleError = (error) => {
    return {
        outcome: false,
        response: error.response && error.response,
        request: error.request && error.request,
        status: error.response && error.response.status && error.response.status,
    }
}


const DELETE = async (url) => {
    try {
        const instance = await getInstance();

        return await instance.delete(url);
    } catch (error) {
        return handleError(error);
    }
}

const getInstance = async () => {
    const token = await storage.getData('@user_token');
    return axios.create({
        baseURL: ENDPOINT,
        headers: {
            'X-AUTH-TOKEN': token,
            'X-ROLE': 'ROLE_ADMIN',
            'X-API-KEY': API_KEY,
        }
    });
}

const API = {
    get,
    post,
    put,
    DELETE
}

export default API;