import React, {useState} from 'react';
import {FaEdit, FaTrash} from "react-icons/all";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {doDeleteNews, doGetSingleNews, doPutNews} from "../../logics/news";
import LoadingSpinner from "../UI/LoadingSpinner";
import NewsFormComponent from "./NewsFormComponent";
import formatter from "../../utils/formatter";

const NewsRow = ({news, onReload}) => {

    const {call} = useAuthCalls();

    const [fullNews, setFullNews] = useState(null);
    const [displayNews, setDisplayNews] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const selectNews = async () => {
        setLoading(true);
        await call(() => doGetSingleNews(news.id),
            (res) => {
                setFullNews(res);
                setDisplayNews(true);
            },
        )
        setLoading(false);
    }

    const hideNews = async () => {
        setDisplayNews(false);
    }

    const activateEditing = async (e) => {
        e.stopPropagation();
        setIsEditing(true);
    }

    const handleDelete = async () => {
        if(window.confirm("Eliminare questa news?")) {
            await call(
                () => doDeleteNews(news.id),
                (res) => {
                    if(res) {
                        onReload && onReload();
                    }
                }
            );
        }
    }

    const handleSubmit = async (e) => {
        let inTwoWeeks = new Date();
        inTwoWeeks.setDate(inTwoWeeks.getDate() + 14);

        let title = e.target.title && e.target.title.value ? e.target.title.value : "";
        let short_description = e.target.short_description && e.target.short_description.value ? e.target.short_description.value : "";
        let description = e.target.description && e.target.description.value ? e.target.description.value : "";
        let deadline = e.target.deadline && e.target.deadline.value ? e.target.deadline.value : inTwoWeeks.toISOString();
        let weight = e.target.weight && e.target.weight.value ? e.target.weight.value : null;

        const fields = {
            title,
            short_description,
            description,
            deadline,
            weight,
        }

        await call(
            () => doPutNews(news.id, fields),
            (res) => {
                if (res) {
                    onReload && onReload();
                    setFullNews(res);
                    setDisplayNews(false);
                    setIsEditing(false);
                }
            },
            'doPutNews',
            () => {
                alert("Errore di modifica della news")
            }
        );
    }

    return (
        <div
            className={`bg-white ${displayNews ? "bg-opacity-20" : "bg-opacity-5 hover:bg-eapp_green hover:bg-opacity-20"}  mb-2 rounded p-2`}>
            {displayNews ?
                isEditing ?
                    <NewsFormComponent onSubmit={handleSubmit} onUndo={() => setIsEditing(false)}
                                       currentNews={fullNews}/>
                    :
                    <div onClick={hideNews} className="cursor-pointer">
                        <div className="flex flex-row justify-between items-center">
                            <div className="font-bold uppercase text-eapp_yellow">{fullNews.title}</div>
                            <div className="flex flex-row items-center gap-1">
                                <div className="flex flex-row gap-1 hover:bg-white hover:bg-opacity-10 p-1 rounded"
                                     onClick={activateEditing}>
                                    <div className="uppercase text-eapp_yellow text-sm font-bold">Modifica</div>
                                    <FaEdit className="text-eapp_yellow"/>
                                </div>
                                <div onClick={handleDelete}
                                     className="flex flex-row gap-1 text-sm items-center text-red-500 hover:text-red-400 cursor-pointer">
                                    <div className="font-bold uppercase">ELIMINA</div>
                                    <FaTrash /></div>
                            </div>
                        </div>

                        {fullNews.short_description
                            ? <div className="mb-4 opacity-50">{fullNews.short_description}</div>
                            : <div className="text-gray-500">Nessuna descrizione breve presente</div>
                        }
                        {fullNews.description
                            ? <div>{fullNews.description}</div>
                            : <div className="text-gray-500">Nessuna descrizione presente</div>
                        }
                        <div className="text-xs text-right mt-2">
                            {fullNews.created_at &&
                            <div>Data creazione: {formatter.formatDate(fullNews.created_at.split(" ")[0])}</div>
                            }
                            {fullNews.deadline &&
                            <div>Data scadenza: {formatter.formatDate(fullNews.deadline)}</div>
                            }
                        </div>
                    </div>
                :
                <div onClick={selectNews}
                     className="cursor-pointer" title="Clicca per visualizzare l'intera news">
                    <div className="flex flex-row justify-between">
                        <div className="font-bold uppercase text-eapp_yellow">{news.title}</div>
                        {news.weight && <div className="uppercase text-xs font-bold">Priorità: {news.weight}</div>}
                    </div>
                    <div className="opacity-50">{news.short_description}</div>
                </div>
            }
            {loading &&
            <div className="flex flex-row items-center gap-2 mt-2">
                <div>Caricamento...</div>
                <LoadingSpinner/>
            </div>
            }
        </div>
    );
};

export default NewsRow;