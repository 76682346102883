// APP
const APP_SET_TOKEN = "APP_SET_TOKEN";
const APP_SET_AUTHENTICATED_STATUS = "APP_SET_AUTHENTICATED_STATUS";
const APP_LOAD_SUBSCRIPTIONS = "APP_LOAD_SUBSCRIPTIONS";
const APP_ADD_SUBSCRIPTION = "APP_ADD_SUBSCRIPTION";
const APP_REMOVE_SUBSCRIPTION = "APP_REMOVE_SUBSCRIPTION";
const APP_UPDATE_SUBSCRIPTION = "APP_UPDATE_SUBSCRIPTION";
const APP_LOAD_ADDONS = "APP_LOAD_ADDONS";
const APP_ADD_ADDON = "APP_ADD_ADDON";
const APP_REMOVE_ADDON = "APP_REMOVE_ADDON";
const APP_UPDATE_ADDON = "APP_UPDATE_ADDON";
const APP_SET_TOAST_CONTENT = "APP_SET_TOAST_CONTENT";
const APP_SET_TOAST_VISIBLE = "APP_SET_TOAST_VISIBLE";

// PROCESSINGS
const PROCESSINGS_LOAD = "PROCESSINGS_LOAD";
const PROCESSINGS_ADD_PROCESSINGS = "PROCESSINGS_ADD_PROCESSINGS";
const PROCESSINGS_RENAME_PROCESSING = "PROCESSINGS_RENAME_PROCESSING";
const PROCESSINGS_DELETE_PROCESSING = "PROCESSINGS_DELETE_PROCESSING";

// USERS
const USERS_LOAD = "USERS_LOAD";
const USERS_ADD_USER = "USERS_ADD_USER";

//USER
const USER_LOAD_SUBSCRIPTIONS = "USER_LOAD_SUBSCRIPTIONS";
const USER_SET_USER_ID = "USER_SET_USER_ID";
const USER_LOAD_USER = "USER_LOAD_USER";

export const APP = {
    APP_SET_TOKEN,
    APP_SET_AUTHENTICATED_STATUS,
    APP_LOAD_SUBSCRIPTIONS,
    APP_ADD_SUBSCRIPTION,
    APP_REMOVE_SUBSCRIPTION,
    APP_UPDATE_SUBSCRIPTION,
    APP_LOAD_ADDONS,
    APP_ADD_ADDON,
    APP_REMOVE_ADDON,
    APP_UPDATE_ADDON,
    APP_SET_TOAST_CONTENT,
    APP_SET_TOAST_VISIBLE,
}

export const PROCESSINGS = {
    PROCESSINGS_LOAD,
    PROCESSINGS_ADD_PROCESSINGS,
    PROCESSINGS_RENAME_PROCESSING,
    PROCESSINGS_DELETE_PROCESSING,
}

export const USERS = {
    USERS_LOAD,
    USERS_ADD_USER,
}

export const USER = {
    USER_LOAD_SUBSCRIPTIONS,
    USER_SET_USER_ID,
    USER_LOAD_USER,
}

const types = {
    ...APP,
    ...PROCESSINGS,
    ...USERS,
    ...USER,
}

export default types;