import React from 'react';

const UserSubscriptionsHeader = ({...props}) => {

    return (
        <div className="bg-white bg-opacity-20 mb-1 rounded p-0.5 uppercase text-xs flex flex-row items-center justify-between">
            <div className="flex-3">Abbonamento</div>
            <div className="flex-2">tipologia</div>
            <div className="flex-2">Inizio</div>
            <div className="flex-2">Fine</div>
            <div className="flex-1">Durata</div>
            <div className="flex-1">Rimanenti</div>
            <div className="flex-.5">

            </div>
        </div>
    )
}

export default UserSubscriptionsHeader;