
import types from '../types';

function loadUserSubscriptions(subscriptions) {
    return {
        type: types.USER_LOAD_SUBSCRIPTIONS,
        subscriptions: subscriptions,
    }
}

function setUserId(id) {
    return {
        type: types.USER_SET_USER_ID,
        id: id,
    }
}

function loadUser(user) {
    return {
        type: types.USER_LOAD_USER,
        user,
    }
}

const actions = {
    loadUserSubscriptions,
    setUserId,
    loadUser,
}

export default actions;
