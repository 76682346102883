import React, {useState} from "react";
import YellowBtn from "../UI/YellowBtn";
import {doGetSubscriptionHistory, doPostUserSubscription} from "../../logics/subscriptions";
import {useApp} from "../../utils/context/app-context";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import useToast from "../../utils/custom_hooks/toast_hook";
import {useUser} from "../../utils/context/user-context";

const UserSubscriptionAddSubscriptionPanel = ({...props}) => {

    const {subscriptions} = useApp();
    const {call} = useAuthCalls();
    const {displayWarning} = useToast();
    const {user_id, loadUserSubscriptions} = useUser();

    const [selectedSubscription, setSelectedSubscription] = useState("");

    const onClickAddSubscription = async () => {

        if (selectedSubscription !== "") {
            await call(
                () => doPostUserSubscription(user_id, selectedSubscription),
                async (res) => {
                    console.log(res);
                    if (res && res.deadline) {
                        await call(
                            () => doGetSubscriptionHistory(user_id),
                            (res) => {
                                if(res) {
                                    loadUserSubscriptions(res)
                                }
                            }
                        )
                        setSelectedSubscription("");
                    }
                },
                'test',
                (err) => {
                    if(err.detail) {
                        alert(err.detail);
                    }
                }
            )
        } else displayWarning("Seleziona un'opzione");
    }


    const onChangeHandler = (e) => {
        setSelectedSubscription(e.target.value);
    }

    return (
        <div className="flex flex-row items-center justify-start">
            <select className="bg-white bg-opacity-5 rounded-full p-1 mr-2"
                    value={subscriptions && subscriptions.length > 0 ? selectedSubscription : ""}
                    onChange={onChangeHandler}>
                <option unselectable="true" disabled value={""} className="bg-black">
                    Seleziona un abbonamento
                </option>

                {subscriptions && subscriptions.length > 0 &&
                subscriptions.map((sub, i) =>
                    <option value={sub.id} key={i} className="bg-black">{sub.name}</option>)
                }
            </select>

            <YellowBtn onClick={onClickAddSubscription}>Aggiungi abbonamento</YellowBtn>
        </div>
    )
}

export default UserSubscriptionAddSubscriptionPanel;