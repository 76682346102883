import React from 'react';
import usersReducer, {defaultState} from "../reducers/users-reducer";
import actions from '../actions/users';

const UsersContext = React.createContext();

function useUsersContextValue() {
    const [state, dispatch] = React.useReducer(usersReducer, defaultState);

    const {users} = state;

    const loadUsers = React.useCallback((users) => dispatch(actions.loadUsers(users)), []);
    const addUser = React.useCallback((user) => dispatch(actions.addUser(user)), []);

    return React.useMemo(() => {
        return {
            state,
            users,
            loadUsers,
            addUser,
        }
    }, [
        state, users,
        loadUsers, addUser,
    ])
}

function UsersProvider(props) {
    const value = useUsersContextValue();
    return <UsersContext.Provider value={value} {...props} />
}

function useUsers() {
    const context = React.useContext(UsersContext);
    if(!context) {
        throw new Error("useUsers must be used within a UsersProvider");
    }

    return context;
}

export {
    UsersProvider,
    useUsers
}