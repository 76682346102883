import React from 'react';
import UserRow from "./UserRow";

const UserTable = ({users, onDelete}) => {
    return (
        <div className="flex flex-col flex-1 overflow-y-auto" style={{minHeight:"200px"}}>
        <table className="w-full" >
            <thead>
                <tr className="text-left uppercase sticky">
                    <th>email</th>
                    <th>nome</th>
                    <th>azienda</th>
                    <th>preventivi creati</th>
                    <th>data richiesta cancellazione</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {users && users.length > 0 ?
                    <>
                        {users.map((user) => <UserRow user={user} key={user.id} onDelete={onDelete}/>)}
                    </>

                    :
                    <tr>
                        <td colSpan={6} className="text-center p-2 ">
                            Nessun utente da eliminare
                        </td>
                    </tr>
                }
            </tbody>
        </table>
        </div>
    );
};

export default UserTable;