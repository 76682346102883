import React, {useState} from 'react';
import InputCreate from "../UI/InputCreate";
import ProcessingsList from "./ProcessingsList";
import {doPostProcessing} from "../../logics/processings";
import {useProcessing} from "../../utils/context/processing-context";
import YellowBtn from "../UI/YellowBtn";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";

const ProcessingCreationPanel = ({...props}) => {

    const {job} = props;

    const {addProcessing} = useProcessing();
    const {call} = useAuthCalls();

    const [processingName, setProcessingName] = useState("");

    const onChangeHandler = (val) => {
        setProcessingName(val);
    }

    const onClickHandler = async () => {
        await call(
            () => doPostProcessing(job, processingName),
            (response) => {
                setProcessingName("");
                addProcessing(response);
            },
            "doPostProcessing"
        );
    }

    return (
        <div className="mt-2">
            <div className="flex flex-row mb-8">
                <InputCreate placeholder="Nome Categoria" onChange={onChangeHandler} value={processingName}
                             className={"flex-1"}/>
                <YellowBtn onClick={onClickHandler}>Aggiungi</YellowBtn>
            </div>
            <ProcessingsList job={job}/>
        </div>
    );
}

export default ProcessingCreationPanel;
