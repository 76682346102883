import React from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Processing from "./Processing";
import {useProcessing} from "../../utils/context/processing-context";
import {doPutReorderProcessings} from "../../logics/processings";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";

const ProcessingsList = ({...props}) => {

    const {onDelete, job} = props;
    const {processings, loadProcessings} = useProcessing();
    const {call} = useAuthCalls();

    const onDragEnd = async (result) => {
        const {destination, source, draggableId} = result;

        if (destination && source) {
            // MAKE API CALL
            const processingId = draggableId.replace("processing_", "");
            const sourceId = source.index;
            const destinationId = destination.index;

            if (sourceId >= 0 && destinationId >= 0) {
                // Reorder graphically
                const reorderedProcessings = Array.from(processings);
                let processingToMove = {...processings[sourceId]}
                reorderedProcessings.splice(sourceId, 1);
                reorderedProcessings.splice(destinationId, 0, processingToMove);

                loadProcessings([...reorderedProcessings]);

                const old_processings = {...processings};

                await call(
                    () => doPutReorderProcessings(processingId, destinationId + 1),
                    () => {
                    },
                    `doPutReorderProcessings ${processingId}`,
                    () => loadProcessings([...old_processings]),
                );
            }
        }
    }

    return (
        <div>
            {processings && processings.length > 0 ?
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`droppable_job_${job.id}`}>
                        {(provided) => (
                            <div {...provided.droppableProps}
                                 ref={provided.innerRef}
                            >
                                {processings.map((processing, index) =>
                                    <Draggable key={processing.id} draggableId={`processing_${processing.id}`}
                                               index={index}>
                                        {(provided) => (
                                            <div {...provided.draggableProps}

                                                 ref={provided.innerRef}>
                                                <Processing key={index} processing={processing} onDelete={onDelete}
                                                            dragHandle={provided.dragHandleProps}/>
                                            </div>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>


                // processings.map((processing, i) => <Processing key={i} processing={processing} onDelete={onDelete}/>)
                :
                <div>Nessuna lavorazione</div>
            }
        </div>
    );
}

export default ProcessingsList;
