import './App.css';
import Home from "./screens/Home";
import Login from "./screens/Login";
import {useEffect} from "react";
import {useApp} from "./utils/context/app-context";

function App() {

    const {authenticated, setAuthenticatedStatus, token, setToken} = useApp();

    useEffect(() => {
        if(!token) {
            setAuthenticatedStatus(false);
        }
    }, [token]);

    useEffect(() => {
        let saved_token = localStorage.getItem('@user_token')
        if(saved_token) {
            setAuthenticatedStatus(true);
            setToken(saved_token);
        } else {
            setAuthenticatedStatus(true);
        }
    }, []);

    return (
      <>
          {authenticated ?
              <Home />
              :
              <Login />
          }
      </>
    );
}

export default App;
