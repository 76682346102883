import React, {useState} from 'react';
import formatter from "../../utils/formatter";
import {FaEdit, FaTrashAlt, FaUndo, GiCancel, GiConfirmed} from "react-icons/all";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {doGetSubscriptionHistory, doGetUserSubscriptions, doPutUserSubscription} from "../../logics/subscriptions";
import {useUser} from "../../utils/context/user-context";

const UserSubscriptionRow = ({setOpen, onDelete, ...props}) => {

    const {userSubscription, actions} = props;

    const {user_id, loadUserSubscriptions} = useUser();
    const {call} = useAuthCalls();

    const [editing, setEditing] = useState(false);
    const [date, setDate] = useState(userSubscription && userSubscription.deadline && formatter.dateToInputDate(userSubscription.deadline));

    const dateChangeHandler = async (e) => {
        setDate(e.target.value)
    }

    const confirmChange = async () => {
        await call(
            () => doPutUserSubscription(user_id, userSubscription.id, {deadline: date}),
            async (res) => {
                if (res) {
                    await call(
                        () => doGetSubscriptionHistory(user_id),
                        (res) => {
                            if (res.expired && res.not_expired) {
                                loadUserSubscriptions([...res.expired, ...res.not_expired])
                            }

                        },
                    )
                    setEditing(false);
                }
            }
        )
    }

    const handleEditDateClick = (e) => {
        e.stopPropagation();
        setEditing(true);
    }

    return (
        <div className="rounded p-1 flex flex-row items-center justify-between cursor-pointer hover:bg-white hover:bg-opacity-40 text-xs" onClick={setOpen}>
            <div className={`uppercase flex-3 flex flex-row items-center ${!userSubscription.active ? "text-gray-400" : ""}`}>
                {userSubscription.subscription.name}
                {userSubscription.active &&
                <span className="ml-1 leading-none text-green-500" style={{fontSize: "8px"}}>Attiva</span>}
                {userSubscription.deleted &&
                <span className="ml-1 leading-none text-red-500" style={{fontSize: "8px"}}>Cancellata</span>}
                {userSubscription.expired &&
                <span className="ml-1 leading-none text-yellow-500" style={{fontSize: "8px"}}>Scaduta</span>}
                {!userSubscription.active && !userSubscription.deleted && !userSubscription.expired &&
                <span className="ml-1 leading-none text-blue-400" style={{fontSize: "8px"}}>attivazione futura</span>}
            </div>
            <div className="flex-2">{formatter.formatSubType(userSubscription.subscription.type)}</div>
            <div className="flex-2">{formatter.formatDate(userSubscription.start_date)}</div>
            <div className="flex-2">
                {editing
                    ? <div className="flex flex-row items-center" onClick={(e) => e.stopPropagation()}>
                        <input type={"date"}
                               value={date}
                               onChange={dateChangeHandler}
                               className="bg-white bg-opacity-5 mr-1"/>
                        <GiConfirmed title="Conferma"
                                     onClick={confirmChange}
                                     className="cursor-pointer hover:text-green-400 mr-1"/>
                        <GiCancel title="Annulla"
                                  onClick={() => setEditing(false)}
                                  className="cursor-pointer hover:text-red-400"/>
                    </div>
                    : <div className="flex flex-row items-center">
                        <div className="mr-2">{formatter.formatDate(userSubscription.deadline)}</div>
                        <FaEdit
                            onClick={handleEditDateClick}
                            title="Modifica"
                            className="hover:text-eapp_yellow cursor-pointer"/>
                    </div>
                }

            </div>
            <div className="flex-1">{userSubscription.subscription.duration}gg</div>
            <div className="flex-1">{formatter.formatMissingDays(userSubscription.missing_days)}gg</div>
            <div className="flex-.5 flex flex-row justify-end ">
                {!userSubscription.deleted &&
                <FaTrashAlt onClick={onDelete}
                            title="Elimina"
                            className="hover:text-red-500 cursor-pointer"
                            style={{fontSize: "15px"}}/>
                }
            </div>
        </div>
    )
}

export default UserSubscriptionRow;
