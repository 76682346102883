import React from 'react';

const InputCreate = ({...props}) => {

    const {value, onChange, onBlur, placeholder, className} = props;

    return(
        <input className={"p-2 mr-2 bg-transparent border-b-2 border-white text-white outline-none focus:border-eapp_yellow " + className}
               type="text"
               placeholder={placeholder}
               onChange={(e) => onChange && onChange(e.target.value)}
               onBlur={(e) => onBlur && onBlur(e.target.value)}
               value={value}
        />
    );
}

export default InputCreate;