import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route, Link
} from "react-router-dom";
import Images from "../images";
import Processings from "./Processings";
import Users from "./Users";
import SideNavBar from "../components/SideNavBar";
import {UsersProvider} from "../utils/context/users-context";
import Subscriptions from "./Subscriptions";
import Addons from "./Addons";
import ToastPanel from "../components/UI/ToastPanel";
import News from "./News";
import {GiCancel, GiHamburgerMenu} from "react-icons/gi";
import DeletionRequests from "./DeletionRequests";

const Home = ({...props}) => {

    const [open, setOpen] = useState(true);

    const handleImageClick = () => {
        if(!open) {
            setOpen(true);
        }
    }

    return (
        <Router>
            <div className="flex flex-col md:flex-row h-full md:h-screen">
                <div className="w-full md:w-1/6 bg-gradient-to-t from-eapp_green_dark to-eapp_green p-4">
                    <div className="flex flex-col items-center mb-4" onClick={handleImageClick}>
                        <img className={"w-4/5"} src={Images.logo_header}/>
                    </div>
                    <div className="absolute top-10 cursor-pointer right-4 md:hidden text-2xl" onClick={() => setOpen(!open)}>
                        {open ?
                            <GiCancel />
                            :
                            <GiHamburgerMenu/>
                        }
                    </div>
                    {open &&
                    <SideNavBar/>
                    }
                </div>

                <div className="w-full md:w-5/6 bg-eapp_black flex-1 p-4 relative h-full" style={{minHeight: "200px"}}>
                    <Switch>
                        <Route path="/processings">
                            <Processings/>
                        </Route>
                        <Route path="/users">
                            <UsersProvider>
                                <Users/>
                            </UsersProvider>
                        </Route>
                        <Route path="/subscriptions">
                            <Subscriptions/>
                        </Route>
                        <Route path="/addons">
                            <Addons/>
                        </Route>
                        <Route path="/news">
                            <News/>
                        </Route>
                        <Route path="/deletion_requests">
                            <DeletionRequests />
                        </Route>
                    </Switch>

                    <ToastPanel/>
                </div>


            </div>


        </Router>
    );
};

export default Home;
