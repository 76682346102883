import React, {useEffect, useState} from 'react';
import ProcessingCreationPanel from "./ProcessingCreationPanel";
import {doGetProcessingsOfJob} from "../../logics/processings";
import {useProcessing} from "../../utils/context/processing-context";
import {HiChevronDown, HiChevronUp} from "react-icons/all";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";

const Job = ({...props}) => {
    const {job} = props;

    const {processings, loadProcessings} = useProcessing();

    const [opened, setOpened] = useState(false);

    useEffect(() => {
        loadProcessings(job.processings);
    }, [loadProcessings, job.processings]);

    return (
        <div className="px-2 md:px-4 p-4 bg-opacity-5 bg-white mb-2 rounded-lg">
            <div className="flex flex-row justify-between items-center">
                <div className="text-white text-2xl font-bold">{job.name}</div>
                <div className="cursor-pointer">
                    {opened ?
                        <HiChevronUp onClick={() => setOpened(false)}
                                     className="text-white w-10 h-10"
                                     title={"Chiudi"}/>
                        :
                        <HiChevronDown onClick={() => setOpened(true)}
                                       className="text-white w-10 h-10"
                                       title={"Apri"}/>
                    }
                </div>
            </div>
            {opened &&
            <ProcessingCreationPanel job={job.id} processings={processings}/>
            }

        </div>
    );
}

export default Job;
