import React, {useState} from 'react';
import {useApp} from "../../utils/context/app-context";
import TextInput from "../UI/TextInput";
import YellowBtn from "../UI/YellowBtn";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {doPostAddon} from "../../logics/addons";

const AddonsCreationPanel = ({...props}) => {

    const {addAddon} = useApp();
    const {call} = useAuthCalls();

    const [name, setName] = useState("");

    const onClickCreate = async () => {
        await call(
            () => doPostAddon(name),
            (res) => addAddon(res),
        )

        setName("");
    }



    return (
        <div>
            <div>Aggiungi un nuovo addon</div>
            <div className="flex flex-row items-center justify-start w-1/3 mb-4">
                <TextInput value={name}
                           onChange={(e) => setName(e.target.value)}
                           placeholder={"Nome"}
                           className={"mr-4"}/>
                <YellowBtn onClick={onClickCreate}>
                    Crea
                </YellowBtn>
            </div>
        </div>
    );
}

export default AddonsCreationPanel;
