import React, {useEffect, useState} from 'react';
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import useToast from "../../utils/custom_hooks/toast_hook";
import {doGetFieldsOfStep, doPostField, doPutCategory, doDeleteStep, doPutReorderField} from "../../logics/processings";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {HiChevronDown, HiChevronUp} from "react-icons/hi";
import {BiTrash, MdDone, MdDragHandle, MdUndo} from "react-icons/all";
import InputCreate from "../UI/InputCreate";
import Field from "./Field";
import UnitMeasureSelect from "../UI/UnitMeasureSelect";

const Category = ({...props}) => {

    const {category, onUpdateName, onDelete, dragHandle} = props;

    const {call} = useAuthCalls();
    const {displayWarning} = useToast();

    const [categoryName, setCategoryName] = useState(category && category.name ? category.name : "");
    const [isEditingName, setIsEditingName] = useState(false);

    const [opened, setOpened] = useState(false);

    const [fields, setFields] = useState([]);

    const [fieldName, setFieldName] = useState("");
    const [price, setPrice] = useState("");
    const [fieldType, setFieldType] = useState("");

    const onClickToggle = async () => {
        if (opened === false) {
            await call(
                () => doGetFieldsOfStep(category.id),
                (response) => setFields(response),
                `doGetFieldsOfStep(${category.id})`
            );
        }
        setOpened(!opened);
    }

    const onClickAddField = async () => {
        if (fieldType !== "") {
            await call(
                () => doPostField(category.id, fieldType, fieldName, price),
                (response) => {
                    setFields([...fields, response]);
                    setFieldName("");
                    setPrice("");
                }
            )
        } else {
            displayWarning("Seleziona un'unità di misura");
        }
    }

    const onFieldDelete = (id) => {
        let _fields = fields.filter((field) => field.id !== id);
        setFields([..._fields]);
    }

    const onClickConfirmName = async () => {
        await call(
            () => doPutCategory(category.id, {"name": categoryName}),
            () => {
                if (onUpdateName) {
                    onUpdateName(category.id, categoryName);
                }
                setIsEditingName(false);
            },
            `doPutCategory(${category.id})`
        );
    }

    const onChangeFieldType = (val) => {
        setFieldType(val);
    }

    const onClickDelete = async () => {
        let deleteMessage = `Confermi cancellazione di "${categoryName}" e il suo contenuto?`;
        if (window.confirm(deleteMessage)) {
            await call(
                () => doDeleteStep(category.id),
                () => {
                    setOpened(false);
                    onDelete(category.id);
                },
                `doDeleteStep(${category.id})`
            );
        }
    }

    const onDragEnd = async (result) => {
        console.log(result);
        const {destination, source, draggableId} = result;

        // MAKE API CALL
        const fieldId = draggableId.replace("field_", "");
        const sourceId = source.index;
        const destinationId = destination.index;

        // Reorder graphically
        const reorderedFields = Array.from(fields);
        let fieldToMove = {...fields[sourceId]}
        reorderedFields.splice(sourceId, 1);
        reorderedFields.splice(destinationId, 0, fieldToMove);

        setFields([...reorderedFields]);

        const old_fields = {...fields};

        await call(
            () => doPutReorderField(fieldId, destinationId + 1),
            () => {
            },
            `doPutReorderField ${fieldId}`,
            () => setFields([...old_fields])
        );
    }

    useEffect(() => {
        if (category) {
            setCategoryName(category.name);
        }
    }, [category]);

    return (
        <div className="p-2 bg-eapp_black mb-4 rounded">
            <div className="flex flex-row justify-between items-center">

                {isEditingName ?
                    <div className="flex flex-row items-center flex-1 mr-12">
                        <InputCreate value={categoryName} onChange={(val) => setCategoryName(val)} className="flex-1"/>
                        <MdDone className="cursor-pointer text-white" size={"1.5rem"} onClick={onClickConfirmName}
                                title={"Salva"}/>
                        <MdUndo className="cursor-pointer text-white" size={"1.5rem"}
                                onClick={() => setIsEditingName(false)} title={"Annulla"}/>
                    </div>
                    :
                    <div className="font-bold text-white cursor-pointer "
                         onClick={() => setIsEditingName(true)}>{categoryName}</div>
                }
                <div className="flex flex-row items-center">

                    <div className="text-white hover:text-eapp_yellow mr-2" {...dragHandle} title={"Sposta"}>
                        <MdDragHandle size={"1.5rem"}/>
                    </div>

                    <button type="button"
                            className="bg-red-600 hover:bg-red-800 w-6 h-6 rounded-lg flex items-center justify-center mr-2"
                            onClick={onClickDelete} title={"Elimina"}>
                        <BiTrash className="text-white"/>
                    </button>

                    <button type={"button"} onClick={onClickToggle}
                            className="cursor-pointer flex align-center justify-center">
                        {opened ?
                            <HiChevronUp size={"2rem"} className="text-white hover:text-eapp_yellow" title={"Chiudi"}/>
                            :
                            <HiChevronDown size={"2rem"} className="text-white hover:text-eapp_yellow" title={"Apri"}/>
                        }
                    </button>
                </div>
            </div>

            {opened &&
            <div className="mt-4">
                <div className="flex flex-row flex-wrap justify-between items-center my-2 mb-8">
                    <InputCreate value={fieldName}
                                 onChange={(val) => setFieldName(val)}
                                 placeholder="Nome lavorazione"
                                 className="bg-transparent border-b-2 border-white flex-3 outline-none focus:border-eapp_yellow mb-4 md:mb-0"/>
                    <InputCreate value={price}

                                 onChange={(val) => setPrice(val)}
                                 placeholder={`€ / ${fieldType}`}
                                 className="bg-transparent border-b-2 border-white flex-1 outline-none focus:border-eapp_yellow mb-4 md:mb-0"/>
                    <UnitMeasureSelect onChange={onChangeFieldType} value={fieldType} className={"flex-1"}/>
                    <button onClick={onClickAddField}
                            className="p-2 rounded bg-eapp_yellow font-bold text-black" type="button">Aggiungi lavorazione
                    </button>
                </div>
                {fields && fields.length > 0 ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={`droppable_${category.id}`}>
                            {(provided) => (
                                <div {...provided.droppableProps}
                                     ref={provided.innerRef}
                                >
                                    {fields.map((field, index) =>
                                        <Draggable key={field.id} draggableId={`field_${field.id}`} index={index}>
                                            {(provided) => (
                                                <div {...provided.draggableProps}
                                                     ref={provided.innerRef}>
                                                    <Field field={field}
                                                           step={category.id}
                                                           key={category.id}
                                                           onDelete={onFieldDelete}
                                                           dragHandle={provided.dragHandleProps}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    :
                    <div className="text-white mb-2">Nessun campo inserito</div>
                }
            </div>
            }
        </div>
    );
}

export default Category;
