import types from '../types';

export const defaultState = {
    processings: [],
}

function processingReducer (state, action) {
    switch(action.type) {
        case types.PROCESSINGS_LOAD: {
            return {
                ...state,
                processings: action.processings,
            }
        }
        case types.PROCESSINGS_ADD_PROCESSINGS: {
            return {
                ...state,
                processings: [
                    ...state.processings,
                    action.processing,
                ]
            }
        }

        case types.PROCESSINGS_RENAME_PROCESSING: {
            let _processings = state.processings.map((processing) => {
                if(processing.id === action.id) {
                    return {
                        ...processing,
                        name: action.name,
                    }
                } else {
                    return {
                        ...processing
                    }
                }
            });

            return {
                ...state,
                processings: [..._processings]
            }
        }

        case types.PROCESSINGS_DELETE_PROCESSING: {
            let _processings = state.processings.filter((processing) => processing.id !== action.id);
            return  {
                ...state,
                processings: [..._processings],
            }
        }
        default:
            throw new Error("Unhandled action type: " + action.type);
    }
}

export default processingReducer;
