import React from 'react';
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {doPostNews} from "../../logics/news";
import NewsFormComponent from "./NewsFormComponent";

const NewsForm = ({onClose, addNews, onReload}) => {

    const {call} = useAuthCalls();

    const handleClose = () => {
        onClose && onClose();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let inAYear = new Date();
        inAYear.setDate(inAYear.getDate() + 30);

        let title = e.target.title && e.target.title.value ? e.target.title.value : "";
        let short_description = e.target.short_description && e.target.short_description.value ? e.target.short_description.value : "";
        let description = e.target.description && e.target.description.value ? e.target.description.value : "";
        let deadline = e.target.deadline && e.target.deadline.value ? e.target.deadline.value : inAYear.toISOString();
        let weight = e.target.weight && e.target.weight.value ? e.target.weight.value : "";

        const fields = {
            title,
            short_description,
            description,
            deadline,
            weight,
        }

        await call(
            () => doPostNews(fields),
            () => {
                onReload && onReload();
                onClose()
            },
            'doPostNews',
            () => {
                alert("Errore di modifica della news")
            }
        );
    }

    return (
        <div>
            <div className="text-lg text-eapp_yellow uppercase font-bold">Aggiungi una nuova news</div>
            <div className="p-4 rounded border border-eapp_yellow mb-4">
                <NewsFormComponent onSubmit={handleSubmit} onUndo={handleClose}/>
            </div>
        </div>

    );
};

export default NewsForm;