import React from 'react';
import {FaSpinner} from "react-icons/all";

const LoadingSpinner = ({...props}) => {

    return (
        <FaSpinner className="animate-spin text-white" />
    )
}

export default LoadingSpinner;
