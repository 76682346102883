import {APP} from "../types";


export const defaultState = {
    authenticated: false,
    token: null,
    subscriptions: [],
    addons: [],
    toastVisible: false,
    toastContent: null,
}

function appReducer(state, action) {
    switch (action.type) {
        case APP.APP_SET_TOKEN:
            return {
                ...state,
                token: action.token
            }
        case APP.APP_SET_AUTHENTICATED_STATUS:
            return {
                ...state,
                authenticated: action.status,
            }
        case APP.APP_LOAD_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.subscriptions,
            }
        case APP.APP_ADD_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: [...state.subscriptions, action.subscription]
            }
        case APP.APP_REMOVE_SUBSCRIPTION:

            let _filtered = state.subscriptions.filter(sub => sub.id !== action.id);

            return {
                ...state,
                subscriptions: [..._filtered]
            }
        case APP.APP_UPDATE_SUBSCRIPTION:
            let _new = state.subscriptions.map((sub) => {
                if (sub.id !== action.subscription.id) {
                    return {...sub}
                } else {
                    return {...action.subscription}
                }
            });

            return {
                ...state,
                subscriptions: [..._new]
            }
        case APP.APP_LOAD_ADDONS: {
            return {
                ...state,
                addons: action.addons,
            }
        }

        case APP.APP_ADD_ADDON: {
            return {
                ...state,
                addons: [...state.addons, action.addon],
            }
        }

        case APP.APP_REMOVE_ADDON: {
            return {
                ...state,
                addons: [...state.addons.filter(addon => addon.id !== action.id)]
            }
        }

        case APP.APP_UPDATE_ADDON: {
            return {
                ...state,
                addons: [...state.addons.map(addon => addon.id !== action.addon.id ? {...addon} : {...action.addon})]
            }
        }
        case APP.APP_SET_TOAST_CONTENT: {
            return {
                ...state,
                toastContent: action.content,
            }
        }
        case APP.APP_SET_TOAST_VISIBLE: {
            return {
                ...state,
                toastVisible: action.status,
            }
        }
        default:
            throw new Error("Unhandled action type: " + action.type);
    }
}

export default appReducer;
