import React from 'react';
import useToast from "../../utils/custom_hooks/toast_hook";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {doDeleteUserForever} from "../../logics/users";

const UserRow = ({user, onDelete}) => {

    const {call} = useAuthCalls();
    const {displayConfirm, displayToast, displayError} = useToast();

    const handleConfirm = () => {
        displayConfirm(
            `Cancellare DEFINITIVAMENTE l'utente ${user.email}?`,
            async () => {
                await call(
                    () => doDeleteUserForever(user.id),
                    (res) => {
                        console.log(res);
                        displayToast(`L'utente ${user.email} è stato cancellato definitivamente`);
                        onDelete && onDelete()
                    },
                    (err) => {
                        displayError(`Errore nella cancellazione dell'utente ${user.email}`)
                    }
                )
            }
        );
    }

    return (
        <tr>
            <td>{user.email}</td>
            <td>{user.name}</td>
            <td>{user.company_name}</td>
            <td>{user.estimates}</td>
            <td>{user.deletion_request}</td>
            <td>
                <button className="bg-red-600 font-medium text-white p-1 rounded" onClick={handleConfirm}>
                    Elimina dati utente
                </button>
            </td>
        </tr>
    );
};

export default UserRow;