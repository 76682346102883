import {useState, useEffect} from 'react';

function useFilter(data, filterFunction, dependencies) {
    const [filterText, setFilterText] = useState("");

    const [filteredData, setFilteredData] = useState(data);

    function filterData() {
        let _filtered = data.filter(filterFunction);
        setFilteredData(_filtered);
    }

    useEffect(() => {
        filterData();
    }, [filterText, ...dependencies]);

    useEffect(() => {
        setFilteredData(data);
        filterData();
    }, [data]);

    return {filterText, setFilterText, filteredData};
}

export default useFilter;