import React, {useEffect, useState} from 'react';
import InputCreate from "../UI/InputCreate";
import {doDeleteField, doPutField} from "../../logics/processings";
import {BiTrash, FaCheck, MdDragHandle} from "react-icons/all";
import UnitMeasureSelect from "../UI/UnitMeasureSelect";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";

const Field = ({...props}) => {

    const {field, step, onDelete, dragHandle} = props;

    const {call} = useAuthCalls();

    const [label, setLabel] = useState(field.label);
    const [value, setValue] = useState(field.value);
    const [type, setType] = useState(field.type);

    const [edited, setEdited] = useState(false);

    const onClickUpdateField = async () => {
        if (edited) {
            let updated_field = {
                "step": step,
                "label": label,
                "value": value,
                "type": type,
            }

            await call(
                () => doPutField(field.id, updated_field),
                () => setEdited(false),
                `doPutField ${field.id}`
            );
        }
    }

    const onClickDeleteHandler = async () => {
        await call(
            () => doDeleteField(field.id),
            () => onDelete && onDelete(field.id),
            `doDeleteField ${field.id}`
        )
    }

    const onChangeTypeHandler = (val) => {
        if (val) {
            console.log("SET TYPE");
            setType(val);
            setEdited(true);
        }
    }

    useEffect(() => {
        if (field) {
            setLabel(field.label);
            setValue(field.value);
        }
    }, [field])

    return (
        <div
            className="mb-2 flex flex-row flex-wrap items-center justify-between border border-white p-2 rounded-xl border-opacity-20">
            <div className="text-white hover:text-eapp_yellow mr-2" {...dragHandle} title={"Sposta"}>
                <MdDragHandle size={"1.5rem"}/>
            </div>

            <InputCreate value={label} onChange={val => {
                setEdited(true);
                setLabel(val)
            }} className={"flex-1"}/>
            <div className="flex flex-row items-center  ml-10">
                <InputCreate value={value} onChange={val => {
                    setEdited(true);
                    setValue(val)
                }} className="w-12"/>
                <div className="mr-2 text-white">€ /</div>
                <UnitMeasureSelect onChange={onChangeTypeHandler} value={type}/>
            </div>

            <div className="mt-4 md:mt-0 cursor-pointer flex flex-row items-center justify-between w-full md:w-auto">
                <FaCheck onClick={onClickUpdateField} size={"1.2rem"} color={edited ? "green" : "white"}
                         className={"mr-2"} title={"Salva modifiche"}/>
                <BiTrash onClick={onClickDeleteHandler} size={"1.2rem"} color={"red"} title={"Elimina"}/>
            </div>

        </div>
    );
};

export default Field;
