import React from 'react';
import {useApp} from "../../utils/context/app-context";

const ToastPanel = ({...props}) => {

    const {toastVisible, toastContent} = useApp();

    return (
        <div className="absolute top-0 left-0 right-0 z-50 flex flex-row justify-center">
                {toastVisible &&
                <div
                    className="bg-black bg-opacity-80 z-10 absolute inset-0 flex flex-row justify-center items-start">
                        {toastContent}
                </div>
                }
        </div>

    );
}

export default ToastPanel;
