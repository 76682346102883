import React from 'react';
import {CSVLink} from "react-csv";
import {FaFileCsv} from "react-icons/all";

const headers = [
    {label: "Nome", key: "name"},
    {label: "Azienda", key: "company_name"},
    {label: "Email", key: "email"},
    {label: "Cellulare", key: "mobile"},
    {label: "Telefono", key: "phone"},
    {label: "Professione", key: "profession"},
    {label: "P.IVA", key: "vat"},
    {label: "Codice fiscale", key: "fiscal_code"},
    {label: "Preventivi creati", key: "estimates"},
    {label: "Versione", key: "version"},
    {label: "Creazione utente", key: "created_at"},
    {label: "Ultimo accesso", key: "last_access_at"},
    {label: "Richiesta cancellazione", key: "deletion_request"},

]

const DownloadDeletedUsers = ({users}) => {

    return (
        <CSVLink data={users}
                 filename="Utenti eliminati"
                 separator={";"}
                 className="border border-white rounded p-1 flex flex-row items-center gap-2"
                 headers={headers}>
            <FaFileCsv />
            <span className="text-sm">Scarica dati degli utenti da cancellare</span>

        </CSVLink>
    );
};

export default DownloadDeletedUsers;