import React, {useState} from 'react';
import YellowBtn from "../UI/YellowBtn";
import TextInput from "../UI/TextInput";
import {doPostChangePassword} from "../../logics/users";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";

const UserChangePasswordPanel = ({...props}) => {

    const {user, onSuccess} = props;

    const {call} = useAuthCalls();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [error, setError] = useState(false);

    const onSubmitChangePassword = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword && password !== "") {
            setError(true);
        } else {
            setError(false);
            await call(
                () => doPostChangePassword(user.id, password),
                (response) => {
                    if(response.message) {
                        onSuccess && onSuccess();
                        setPassword("");
                        setConfirmPassword("");
                    }
                }
            )
        }
    }

    return (
        <div>
            <div>Cambio password</div>
            <form className="flex flex-row justify-between items-center"
                  onSubmit={onSubmitChangePassword}
                  autoComplete="off">
                <TextInput type="password"
                           placeholder="Inserisci nuova password"
                           required
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           className="flex-1 mr-2"
                />
                <TextInput type="password"
                           placeholder="Ripeti Password"
                           required={true}
                           value={confirmPassword}
                           onChange={(e) => setConfirmPassword(e.target.value)}
                           className="flex-1 mr-2"
                />
                <YellowBtn type="submit">Conferma cambio password</YellowBtn>
            </form>
            {error &&
            <div className="text-yellow-600">Le password non combaciano</div>
            }
        </div>
    );
}

export default UserChangePasswordPanel;
