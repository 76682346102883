const storeData = async (label, value) => {
    try {
        await localStorage.setItem(label, value);
    } catch (e) {
        console.log(e);
    }
}

const getData = async (label) => {
    try {
        const value = await localStorage.getItem(label);
        if (value !== null) {
            return value;
        }
        return false;
    } catch (e) {
        return false;
    }
}

const clear = async () => {
    try {
        await localStorage.clear();
        return true;
    } catch (e) {
        console.log("Can't clear localstorage");
        console.log("ERROR");
        console.log(e);
        return false;
    }
}

const storage = {
    getData,
    storeData,
    clear,
}

export default storage;