import API from "./config";

async function doGetNews() {
    return await API.get('/news');
}

async function doGetSingleNews(id) {
    return await API.get(`/news/${id}`);
}

async function doPostNews(fields) {
    let params = new URLSearchParams({...fields})
    return await API.post('/news/', params);
}

async function doPutNews(id, fields) {
    return await API.put(`/news/${id}`, fields);
}

async function doDeleteNews(id) {
    return await API.DELETE(`/news/${id}`);
}

export {
    doGetNews,
    doGetSingleNews,
    doPostNews,
    doPutNews,
    doDeleteNews,
}