import React, {useEffect, useState} from 'react';
import TextInput from "../UI/TextInput";
import YellowBtn from "../UI/YellowBtn";
import {FaToggleOff, FaToggleOn} from "react-icons/all";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {useApp} from "../../utils/context/app-context";
import {doPutAddon} from "../../logics/addons";

const AddonPanel = ({...props}) => {

    const {addon} = props;

    const {updateAddon} = useApp();
    const {call} = useAuthCalls();

    const [name, setName] = useState(addon.name);
    const [description, setDescription] = useState(addon.description);
    const [price, setPrice] = useState(addon.price);
    const [activable, setActivable] = useState(addon.activable);

    const handleChange = async (fields) => {
        await call(
            () => doPutAddon(addon.id, fields),
            (res) => {
                console.log(res);
                updateAddon({
                    ...res
                });
            }
        )
    }

    useEffect(() => {
        addon.name !== name && setName(addon.name);
        addon.description !== description && setDescription(addon.description);
        addon.price !== price && setPrice(addon.price);
        addon.activable !== activable && setActivable(addon.activable);
    }, [addon])

    return (
        <div className={`p-2 rounded-lg bg-white bg-opacity-20 ${addon.activable && "border-2 border-eapp_yellow"}`}>
            <TextInput value={name}
                       label={"Nome"}
                       onChange={(e) => setName(e.target.value)}
                       onBlur={() => handleChange({name: name})}
                       className={`${addon.name !== name && "border border-eapp_yellow"} w-full`}
                       placeholder={"Nome"}/>

            <TextInput value={description}
                       label={"Descrizione"}
                       onChange={(e) => setDescription(e.target.value)}
                       onBlur={() => handleChange({description: description})}
                       className={`${addon.description !== description && "border border-eapp_yellow"} w-full`}
                       placeholder={"Descrizione"}/>

            <TextInput value={price}
                       label={"prezzo"}
                       onChange={(e) => setPrice(e.target.value)}
                       onBlur={() => handleChange({price: price})}
                       className={`${addon.price !== price && "border border-eapp_yellow"} w-full`}
                       placeholder={"Prezzo"}/>
            <div className="flex flex-row items-center justify-start">
                <div>Attivazione</div>
                <div className="ml-2 pt-1 cursor-pointer hover:opacity-80" onClick={() => handleChange({activable: !activable})}>
                    {activable
                        ? <FaToggleOn/>
                        : <FaToggleOff/>
                    }
                </div>
            </div>
        </div>
    )
}

export default AddonPanel;