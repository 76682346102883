import React from 'react';

const YellowBtn = ({...props}) => {

    const {onClick, type= "button", className = ""} = props;

    return (
        <button className={`text-black uppercase p-1 bg-eapp_yellow font-bold rounded hover:bg-eapp_green hover:text-white ${className}`}
                type={type}
                onClick={onClick && onClick}>
            {props.children}
        </button>
    );
}

export default YellowBtn;
