import React, {useEffect, useState} from 'react';
import {MediumCell, SmallCell} from "../UI/TableCells";
import {BiMobile, BiPhone, BiUser, CgExtensionAdd, HiOutlineOfficeBuilding, RiLockPasswordFill} from "react-icons/all";
import UserChangePasswordPanel from "./UserChangePasswordPanel";
import UserSubscriptionPanel from "./UserSubscriptionPanel";
import {useUser} from "../../utils/context/user-context";
import {doGetUser} from "../../logics/users";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";

const UserRow = ({...props}) => {

    const {user} = props;

    const {setUserId, loadUser} = useUser();
    const {call} = useAuthCalls();

    const [panelOpen, setPanelOpen] = useState(false);
    const [panelOption, setPanelOption] = useState('password');

    const togglePanel = (panel) => {
        if (panelOption !== panel) {
            setPanelOption(panel);
            if (!panelOpen) {
                setPanelOpen(true);
            }
        } else {
            setPanelOpen(!panelOpen);
        }
    }

    const getPanel = () => {
        switch (panelOption) {
            case 'password':
                return <UserChangePasswordPanel user={user} onSuccess={() => setPanelOpen(!panelOpen)}/>;
            case 'subscriptions':
                return <UserSubscriptionPanel user={user}/>;
            default: {

            }
        }
    }

    const handleUserClick = async () => {
        if(!panelOpen) {
            await call(
                () => doGetUser(user.id),
                (res) => {
                    loadUser(res);
                    console.log(res);
                }
            )
        }
        togglePanel('subscriptions');
    }

    const handleChangePasswordClick = (e) => {
        e.stopPropagation();
        togglePanel('password');
    }

    useEffect(() => {
        if (user) {
            setUserId(user.id);
        }
    }, []);

    return (
        <div
            className={`border-2 rounded-lg overflow-hidden odd:bg-white odd:bg-opacity-5 mb-1 text-sm ${panelOpen ? "border-eapp_yellow mb-2" : "border-transparent"}`}>
            <div
                className={`flex flex-row cursor-pointer  ${panelOpen ? "bg-eapp_yellow text-black font-medium" : "hover:bg-white hover:bg-opacity-10"}`}
                onClick={handleUserClick}>
                <SmallCell>{user.id}</SmallCell>
                <MediumCell>
                    <div>
                        {user.role && user.role === "ROLE_ADMIN" &&
                        <div className={`font-medium ${panelOpen ? "" : "text-eapp_yellow "} leading-none`} style={{fontSize: "8px"}}>ADMIN</div>
                        }
                        <div className="flex flex-row items-center">
                            <BiUser/>
                            <div className="ml-1 font-medium">{user.name}</div>
                        </div>
                        <div className="flex flex-row items-center">
                            <HiOutlineOfficeBuilding/>
                            <div className="ml-1">{user.company_name ? user.company_name : <span className="italic text-xs text-gray-500">Nome aziendale</span>}</div>
                        </div>
                    </div>
                </MediumCell>
                <MediumCell>{user.email}</MediumCell>
                <MediumCell>
                    <div className="flex flex-col items-start">
                        <div className="flex flex-row items-center justify-start">
                            <BiMobile/>
                            <div>{user.mobile}</div>
                        </div>
                        <div className="flex flex-row items-center">
                            <BiPhone/>
                            <div>{user.phone}</div>
                        </div>
                    </div>
                </MediumCell>
                <SmallCell>
                    {user.estimates}
                </SmallCell>
                <SmallCell>
                    <RiLockPasswordFill title={"Cambia Password"}
                                        onClick={handleChangePasswordClick}
                                        className="cursor-pointer"
                    />
                </SmallCell>
            </div>

            {panelOpen &&
            <div className="flex-1 p-4 py-2">
                {getPanel()}
            </div>
            }
        </div>
    );
}

export default UserRow;
