import React, {useEffect, useState} from "react";
import {useProcessing} from "../../utils/context/processing-context";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import {
    doDeleteProcessing,
    doGetStepsOfProcessing,
    doPostStep,
    doPutProcessing,
} from "../../logics/processings";
import {doPutReorderSteps} from '../../logics/processings';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {BiTrash, MdDone, MdDragHandle, MdUndo} from "react-icons/all";
import {HiChevronDown, HiChevronUp} from "react-icons/hi"
import Category from "./Category";
import InputCreate from "../UI/InputCreate";
import YellowBtn from "../UI/YellowBtn";
import useToast from "../../utils/custom_hooks/toast_hook";

const Processing = ({...props}) => {

    const {processing, dragHandle} = props;

    const {renameProcessing, deleteProcessing} = useProcessing();
    const {call} = useAuthCalls();
    const {displayConfirm} = useToast();

    const [opened, setOpened] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [isEditingName, setIsEditingName] = useState(false);
    const [processingName, setProcessingName] = useState(processing && processing.name ? processing.name : "");

    const [categoryName, setCategoryName] = useState("");

    const [steps, setSteps] = useState([]);

    const onClickToggle = async () => {
        if (opened === false && !loaded) {
            await call(
                () => doGetStepsOfProcessing(processing.id),
                (response) => {
                    setSteps(response);
                    setLoaded(true)
                },
                `doGetStepsOfProcessing ${processing.id}`
            )
        }
        setOpened(!opened);
    }

    const onClickEditName = () => {
        setIsEditingName(!isEditingName);
    }

    const onClickAddCategory = async () => {
        await call(
            () => doPostStep(processing.id, categoryName),
            (response) => {
                setSteps([...steps, response]);
                setCategoryName("");
            },
            `doPostStep ${processing.id}`
        )
    }

    const onClickConfirmName = async () => {
        if (processingName.length > 0) {
            await call(
                () => doPutProcessing(processing.id, {"name": processingName}),
                (response) => {
                    renameProcessing(processing.id, processingName);
                    setIsEditingName(false);
                },
                `doPutProcessing ${processing.id}`
            )
        }
    }

    const onUpdateNameCategory = (id, name) => {
        let _steps = steps.map((step) => {
            if (step.id === id) {
                return {
                    ...step,
                    name: name,
                }
            } else {
                return {...step};
            }
        })

        setSteps([..._steps]);
    }

    const onDeleteCategory = (id) => {
        let filtered_steps = steps.filter((step) => step.id !== id);
        setSteps([...filtered_steps]);
    }

    const onClickDelete = async () => {
       displayConfirm(
           `Cancellare "${processing.name}" e tutto il suo contenuto?`,
           async () => {
               await call(
                   () => doDeleteProcessing(processing.id),
                   () => {
                       setOpened(false);
                       deleteProcessing(processing.id);
                   },
                   `doDeleteProcessing ${processing.id}`
               );
           }
       );
    }

    const onDragEnd = async (result) => {
        console.log(result);
        const {destination, source, draggableId} = result;

        if(source && destination) {
            // MAKE API CALL
            const stepId = draggableId.replace("step_", "");
            const sourceId = source.index;
            const destinationId = destination.index;

            if(sourceId >= 0 && destinationId >= 0) {
                // Reorder graphically
                const reorderedSteps = Array.from(steps);
                let fieldToMove = {...steps[sourceId]}
                reorderedSteps.splice(sourceId, 1);
                reorderedSteps.splice(destinationId, 0, fieldToMove);

                setSteps([...reorderedSteps]);

                const old_steps = {...steps};

                await call(
                    () => doPutReorderSteps(stepId, destinationId + 1),
                    () => {
                    },
                    `doPutReorderSteps ${stepId}`,
                    () => setSteps([...old_steps])
                );
            }
        }
    }

    useEffect(() => {
        if (processing)
            setProcessingName(processing.name);
    }, [processing]);

    return (
        <div className="bg-gradient-to-r from-eapp_green_dark to-eapp_green px-2 md:px-4 p-4 mb-2 rounded ">
            <div className="flex flex-row justify-between items-center">
                {isEditingName ?
                    <div className="flex flex-row items-center flex-1 mr-12">
                        <InputCreate value={processingName} onChange={(val) => setProcessingName(val)}
                                     className="flex-1"/>
                        <MdDone className="cursor-pointer" size={"1.5rem"} onClick={onClickConfirmName}
                                title={"Salva"}/>
                        <MdUndo className="cursor-pointer" size={"1.5rem"} onClick={() => setIsEditingName(false)}
                                title={"Annulla"}/>
                    </div>
                    :
                    <div className="text-white font-bold text-xl cursor-pointer flex-1 "
                         onClick={onClickEditName}>{processingName}</div>
                }
                <div className="flex flex-row items-center">
                    <div
                        className="bg-blue-600 hover:text-eapp_yellow w-6 h-6 rounded-lg flex items-center justify-center mr-2"
                        title="Sposta" {...dragHandle}>
                        <MdDragHandle size={"1.4rem"}/>
                    </div>
                    <button type="button"
                            className="bg-red-600 hover:bg-red-800 w-6 h-6 rounded-lg flex items-center justify-center mr-2"
                            onClick={onClickDelete} title={"Elimina"}>
                        <BiTrash className="text-white "/>
                    </button>
                    <button onClick={onClickToggle}
                            type="button"
                            className="cursor-pointer flex align-center justify-center text-black">
                        {opened ?
                            <HiChevronUp size={"2rem"}
                                         className="rounded-lg w-6 h-6 hover:bg-eapp_black hover:text-white bg-white"
                                         title={"Chiudi"}/>
                            :
                            <HiChevronDown size={"2rem"}
                                           className="rounded-lg w-6 h-6 hover:bg-eapp_black hover:text-white bg-white"
                                           title={"Apri"}/>
                        }
                    </button>
                </div>
            </div>

            {opened &&
            <div className="mt-4">

                <div className="flex flex-row">

                </div>

                <div className="flex flex-row my-4">
                    <InputCreate placeholder="Nome sottocategoria"
                                 value={categoryName}
                                 onChange={(val) => setCategoryName(val)}
                                 className="flex-1"/>
                    <YellowBtn onClick={onClickAddCategory}>Aggiungi categoria</YellowBtn>
                </div>
                {steps && steps.length > 0 ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={`droppable_process_${processing.id}`}>
                            {(provided) => (
                                <div {...provided.droppableProps}
                                     ref={provided.innerRef}
                                >
                                    {steps.map((step, index) =>
                                        <Draggable key={step.id} draggableId={`step_${step.id}`} index={index}>
                                            {(provided) => (
                                                <div {...provided.draggableProps}
                                                     ref={provided.innerRef}>
                                                    {/*<Field field={field} step={category.id} key={category.id} onDelete={onFieldDelete}/>*/}
                                                    <Category category={step}
                                                              onUpdateName={onUpdateNameCategory}
                                                              onDelete={onDeleteCategory}
                                                              dragHandle={provided.dragHandleProps}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    :
                    <div className="text-white mb-2">Nessuna sottocategoria inserita</div>
                }

            </div>
            }
        </div>
    );
}

export default Processing;
