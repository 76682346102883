import React from 'react';
import {useUser} from "../../utils/context/user-context";
import LoadingSpinner from "../UI/LoadingSpinner";
import AlignedRow from "../UI/AlignedRow";
import {
    BiMobile,
    BiPhone,
    BiUser,
    FaMapMarkerAlt,
    GoUnverified,
    HiOutlineOfficeBuilding,
    MdEmail,
    MdVerified,
    HiOutlineDeviceMobile, FaPhone
} from "react-icons/all";
import formatter from "../../utils/formatter";

const UserDetails = () => {

    const {user} = useUser();

    return (
        <div className="mb-4">
            {user ?
                <div className="mb-2">
                    <div
                        className="uppercase text-xs font-medium text-gray-400 border-b-2 mb-1 border-gray-400">Dettagli
                    </div>
                    <div className="flex flex-row items-start gap-4">
                        <div className="w-48 ">
                            {user.image_url ?
                                <img src={user.image_url}/>
                                :
                                <div
                                    className="rounded-lg bg-white bg-opacity-5 py-8 w-full h-full flex flex-col items-center justify-center text-center">
                                    <div className="italic text-gray-500">Nessuna immagine presente</div>
                                </div>
                            }

                        </div>
                        <div className="flex-1">
                            <div className="text-xl font-medium mb-2">{user.name}</div>
                            <div className="w-full items-start flex flex-row">
                                <div className="flex-1">

                                    <AlignedRow className="gap-2">
                                        <MdEmail/>
                                        <div>{user.email}</div>
                                    </AlignedRow>
                                    <AlignedRow className="gap-2">
                                        <BiMobile title="Mobile"/>
                                        <div>{user.mobile}</div>
                                    </AlignedRow>
                                    <AlignedRow className="gap-2">
                                        <FaPhone title="Phone" />
                                        <div>{user.phone}</div>
                                    </AlignedRow>
                                    <AlignedRow className="gap-2">
                                        <HiOutlineOfficeBuilding title="Nome azienda"/>
                                        <div className="">{user.company_name}</div>
                                    </AlignedRow>
                                    <AlignedRow className="gap-2">
                                        <FaMapMarkerAlt title="Indirizzo"/>
                                        <div>{user.address} {user.zip} {user.city} , {user.province}</div>
                                    </AlignedRow>
                                </div>
                                <div className="flex-1">
                                    <AlignedRow className="gap-2">
                                        <div>Data creazione:</div>
                                        <div className=" text-xs">
                                            {user.created_at ?
                                                formatter.formatDate(user.created_at)
                                                :
                                                <span className="text-yellow-400 text-xs">Non rilevato</span>
                                            }
                                        </div>
                                    </AlignedRow>
                                    <AlignedRow className="gap-2">
                                        <div>Ultimo accesso:</div>
                                        <div className=" text-xs">
                                            {user.last_access_at ?
                                                formatter.formatDate(user.last_access_at)
                                                :
                                                <span className="text-yellow-400 text-xs">Non rilevato</span>
                                            }
                                        </div>
                                    </AlignedRow>
                                    <AlignedRow className="gap-2">
                                        <div>Versione APP:</div>
                                        <div className=" text-xs">
                                            {user.version ?
                                                user.version
                                                :
                                                <span className="text-yellow-400">Non rilevata</span>
                                            }
                                        </div>
                                    </AlignedRow>
                                    {user.verified_at ?
                                        <AlignedRow className="gap-2 text-blue-400">
                                            <MdVerified/>
                                            <div>Email verificata - <span
                                                className="text-xs">{formatter.formatDate(user.verified_at)}</span>
                                            </div>
                                        </AlignedRow>
                                        :
                                        <AlignedRow className="gap-2 text-yellow-600">
                                            <GoUnverified/>
                                            <div>Email non verificata</div>
                                        </AlignedRow>
                                    }
                                </div>
                                <div className="flex-1">

                                    <div className="flex flex-col">
                                        <div>Preventivi creati: {user.estimates}</div>
                                        <div>C.F.: {user.fiscal_code}</div>
                                        <div>P.IVA: {user.vat}</div>
                                        <div>PEC: {user.pec}</div>
                                    </div>

                                    <div>
                                        Professione: {user.profession}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <AlignedRow className="gap-1">
                    <div>Caricamento</div>
                    <LoadingSpinner/>
                </AlignedRow>
            }
        </div>
    );
};

export default UserDetails;