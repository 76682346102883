import React, {useState} from 'react';
import {doPostUser} from "../../logics/users";
import YellowBtn from "../UI/YellowBtn";
import {useUsers} from "../../utils/context/users-context";
import TextInput from "../UI/TextInput";
import {IoCreateOutline, MdClose} from "react-icons/all";
import useAuthCalls from "../../utils/custom_hooks/auth_calls_hook";
import Title from "../UI/Title";
import AlignedRow from "../UI/AlignedRow";

const UserCreationForm = () => {

    const {addUser} = useUsers();
    const {call} = useAuthCalls();

    const [panelOpen, setPanelOpen] = useState(false);

    const [name, setName] = useState("");
    const [company_name, setCompanyName] = useState("");

    const [mobile, setMobile] = useState("");
    const [phone, setPhone] = useState("");

    const [fiscal_code, setFiscalCode] = useState("");
    const [vat, setVat] = useState("");

    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [province, setProvince] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        const fields = {
            name,
            company_name,
            mobile,
            phone,
            fiscal_code,
            vat,
            address,
            city,
            zip,
            province,
            email,
            password,
        }

        await call(
            () => doPostUser(fields),
            async (response) => {

                if (response.status === 400) {
                    alert("Errore");
                }

                let new_user = response;
                if (new_user.updated_at)
                    delete new_user.updated_at;
                if (new_user.deleted_at)
                    delete new_user.created_at;
                if (new_user.salt)
                    delete new_user.salt;

                await addUser(new_user);
                resetInputs();
            },
            "doPostUser"
        )
    }

    const resetInputs = () => {
        setName("");
        setCompanyName("");
        setMobile("");
        setPhone("");
        setFiscalCode("");
        setVat("");
        setAddress("");
        setCity("");
        setZip("");
        setProvince("");
        setEmail("");
        setPassword("");

        setPanelOpen(false);
    }

    return (
        <div className="text-white  ">
            <AlignedRow className="justify-between">
            <Title>Utenti</Title>
            <div className="flex flex-row items-center cursor-pointer hover:text-eapp_yellow" onClick={() => {
                setPanelOpen(!panelOpen)
            }}>
                <div className="uppercase mr-4">{panelOpen ? "Chiudi" : "Crea un nuovo utente"}</div>
                {panelOpen ? <MdClose /> : <IoCreateOutline />}
            </div>
            </AlignedRow>
            {panelOpen &&
            <form onSubmit={onSubmitHandler} className="flex flex-col mt-3 text-xs">
                <div className="flex flex-col md:flex-row">
                    <TextInput type="text"
                               placeholder="Nome e cognome"
                               required={true}
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                               className={`${inputClassname} md:mr-1`}/>
                    <TextInput type="text"
                               placeholder="Azienda"
                               value={company_name}
                               onChange={(e) => setCompanyName(e.target.value)}
                               className={inputClassname}/>
                </div>

                <TextInput type="text"
                           placeholder="Codice fiscale"
                           required={true}
                           value={fiscal_code}
                           onChange={(e) => setFiscalCode(e.target.value)}
                           className={`${inputClassname} mt-4`}/>
                <TextInput type="text"
                           placeholder="Partita IVA"
                           value={vat}
                           onChange={(e) => setVat(e.target.value)}
                           className={`${inputClassname}`}/>

                <div className="flex flex-col md:flex-row mt-4">
                    <TextInput type="text"
                               placeholder="Cellulare"
                               required={true}
                               value={mobile}
                               onChange={(e) => setMobile(e.target.value)}
                               className={`${inputClassname} md:mr-1`}/>
                    <TextInput type="text"
                               placeholder="Telefono"
                               value={phone}
                               onChange={(e) => setPhone(e.target.value)}
                               className={inputClassname}/>
                </div>

                <TextInput type="text"
                           placeholder="Indirizzo"
                           value={address}
                           onChange={(e) => setAddress(e.target.value)}
                           className={`${inputClassname} mt-4`}/>

                <div className="flex flex-col md:flex-row">
                    <TextInput type="text"
                               placeholder="CAP"
                               value={zip}
                               onChange={(e) => setZip(e.target.value)}
                               className={`${inputClassname} md:mr-1`}/>
                    <TextInput type="text"
                               placeholder="Città"
                               value={city}
                               onChange={(e) => setCity(e.target.value)}
                               className={`${inputClassname} md:mr-1`}/>
                    <TextInput type="text"
                               placeholder="Provincia"
                               value={province}
                               onChange={(e) => setProvince(e.target.value)}
                               className={inputClassname}/>
                </div>

                <div className="flex flex-col md:flex-row mt-4">
                    <TextInput type="email"
                               placeholder="Email"
                               className={`${inputClassname} md:mr-1`}
                               value={email}
                               required={true}
                               onChange={(e) => setEmail(e.target.value)}/>
                    <TextInput type="text"
                               placeholder="Password"
                               className={inputClassname}
                               value={password}
                               required={true}
                               onChange={(e) => setPassword(e.target.value)}/>
                </div>

                <div className="flex flex-row justify-center my-4">
                    <YellowBtn type="submit">Aggiungi utente</YellowBtn>
                </div>
            </form>
            }
        </div>
    );
}

const inputClassname = "bg-white bg-opacity-5 mb-1 p-1 flex-1";

export default UserCreationForm;
