import React, {useEffect, useState} from 'react';
import Title from "../components/UI/Title";
import useAuthCalls from "../utils/custom_hooks/auth_calls_hook";
import {doGetSubscriptions} from "../logics/subscriptions";
import SubscriptionPanel from "../components/Subscriptions/SubscriptionPanel";
import {useApp} from "../utils/context/app-context";
import YellowBtn from "../components/UI/YellowBtn";
import {FaEdit} from "react-icons/all";
import SubscriptionCreationPanel from "../components/Subscriptions/SubscriptionCreationPanel";

const Subscriptions = ({...props}) => {

    const {subscriptions, loadSubscriptions} = useApp();
    const {call} = useAuthCalls();

    const [creationPanelOpen, setCreationPanelOpen] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            await call(
                () => doGetSubscriptions(),
                (res) => loadSubscriptions(res),
                'doGetSubscriptions'
            )
        }
        loadData();
    }, [])

    return (
        <div className="text-white">
            <Title>Abbonamenti</Title>

            <div className={"flex flex-col justify-start items-start mb-4"}>
                <YellowBtn
                           onClick={() => setCreationPanelOpen(!creationPanelOpen)}>
                    {creationPanelOpen
                        ? <span>Chiudi</span>
                        : <span className="flex flex-row items-center">Crea nuovo <FaEdit className="ml-2"/></span>}

                </YellowBtn>
                {creationPanelOpen &&
                <SubscriptionCreationPanel onSubmit={() => setCreationPanelOpen(false)}/>
                }
            </div>


            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {subscriptions && subscriptions.length > 0 &&
                subscriptions.map((sub) => <SubscriptionPanel subscription={sub} key={sub.id}/>)
                }
            </div>
        </div>
    )
}

export default Subscriptions;
