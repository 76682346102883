import React, {useEffect, useState} from 'react';
import {GiConfirmed, MdCancel} from "react-icons/all";

const EditableField = ({...props}) => {
    const {value, setValue, type, classNameInput, append, label} = props;

    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value ? value : "");

    const onConfirm = () => {
        setValue(currentValue);
        setIsEditing(false);
    }

    useEffect(() => {
        if (currentValue !== value) {
            setCurrentValue(value);
        }
    }, [value])

    return (
        <div>
            <div className="uppercase text-xs text-gray-400">
                {label ? label : ""}
            </div>
            {isEditing
                ? <div className="flex flex-row items-center">
                    <input type={type ? type : "text"}
                           value={currentValue}
                           className={`flex-1 rounded p-1 bg-white bg-opacity-5 ${classNameInput}`}
                           onChange={(e) => setCurrentValue(e.target.value)}/>
                    <GiConfirmed title="Conferma"
                                 onClick={onConfirm}/>
                    <MdCancel title="Annulla"
                              onClick={() => setIsEditing(false)}/>

                </div>
                : <div onDoubleClick={() => setIsEditing(true)}>
                    {value}{append ? append : ""}
                </div>
            }


        </div>
    );

}

export default EditableField;