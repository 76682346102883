import types from '../types'

export const defaultState = {
    user_id: null,
    userSubscriptions: [],
    user: null,
}

function userReducer(state, action) {
    switch (action.type) {

        case types.USER_LOAD_SUBSCRIPTIONS:
            let expired = action.subscriptions.expired;
            let not_expired = action.subscriptions.not_expired;
            let deleted = action.subscriptions.deleted;

            let _subscriptions = []
            if (action.subscriptions.expired && action.subscriptions.not_expired && action.subscriptions.deleted) {
                _subscriptions = [
                    ...not_expired.map((sub) => {
                        return {
                            ...sub,
                            expired: false,
                            deleted: false,
                        }
                    }),
                    ...expired.map((sub) => {
                        return {
                            ...sub,
                            expired: true,
                            deleted: false,
                        }
                    }),
                    ...deleted.map((sub) => {
                        return {
                            ...sub,
                            expired: true,
                            deleted: true,
                        }
                    })
                ]
            } else {
                _subscriptions = action.subscriptions;
            }

            return {
                ...state,
                userSubscriptions: [..._subscriptions],
            }
        case types.USER_SET_USER_ID:
            return {
                ...state,
                user_id: action.id,
            }
        case types.USER_LOAD_USER:
            return {
                ...state,
                user: action.user,
            }
        default:
            throw new Error("Unhandled action type: " + action.type);
    }
}

export default userReducer;
