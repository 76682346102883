import React, {useState, useEffect} from 'react';

function usePagination(data, itemsPerPage) {
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    function next() {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage > 0 ? maxPage : 1));
    }

    function prev() {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page)
        setCurrentPage(Math.min(pageNumber, maxPage > 0 ? maxPage : 1));
    }

    function currentData() {
        const begin = (currentPage - 1) * itemsPerPage;
        const end = begin + parseInt(itemsPerPage);
        if(data && data.length > 0) return data.slice(begin, end);
        else return [];
    }

    function getPaginationString() {
        return `da ${((currentPage - 1) * itemsPerPage) + 1} a ${(currentPage * itemsPerPage) < data.length ?
            currentPage * itemsPerPage : data.length} su ${data.length} elementi`;
    }

    function getInterval() {
        let firstItem = itemsPerPage * (currentPage - 1) + 1;
        let lastItem = itemsPerPage * (currentPage) > data.length ? data.length : itemsPerPage * (currentPage);
        return `${firstItem} - ${lastItem}`;
    }

    useEffect(() => {
        if(currentPage > maxPage) {
            if(maxPage > 0)
                setCurrentPage(1);
        }
    }, [data, setCurrentPage]);

    useEffect(() => {
        if(currentPage > maxPage) {
            let target = Math.ceil(data.length / itemsPerPage)
            setCurrentPage(target > 0 ? target : 1)
        }
    }, [itemsPerPage])

    return {next, prev, jump, currentData, currentPage, maxPage, getPaginationString, getInterval};
}

export default usePagination;
